import 'source-map-support/register';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from './modules/store/reducers/rootReducer';
import { Provider } from "react-redux";
import thunk from "redux-thunk"
//import {getFirestore, reduxFirestore} from "redux-firestore";
import { ReactReduxFirebaseProvider, firebaseReducer } from "react-redux-firebase";
import { createFirestoreInstance } from 'redux-firestore' // <- needed if using firestore
//import firebaseWrapper from "./modules/firebase/firebaseWrapper";

import firebase, { firestore } from 'firebase/app';
import 'firebase/firebase-firestore';
import 'firebase/auth';

import './modules/store/actions/globalActions';
import { set5Claims, setLoggedInUserClaims } from './modules/store/actions/globalActions';


const firebaseConfig = {
  apiKey: "AIzaSyC_8UtJB9bxMMlLQDFCr_0W0XiDLdhEj7Y",
  authDomain: "farrowtechdev.firebaseapp.com",
  databaseURL: "https://farrowtechdev.firebaseio.com",
  projectId: "farrowtechdev",
  storageBucket: "farrowtechdev.appspot.com",
  messagingSenderId: "859199143128",
  appId: "1:859199143128:web:2475aba320ae9dafb76bad"
};

const firebaseConfigProd = {
  apiKey: "AIzaSyDaRjmgpFAqjrrsf_KYXIccO-Thy9WRnWI",
  authDomain: "farrowtechprod.firebaseapp.com",
  databaseURL: "https://farrowtechprod.firebaseio.com",
  projectId: "farrowtechprod",
  storageBucket: "farrowtechprod.appspot.com",
  messagingSenderId: "286888488099",
  appId: "1:286888488099:web:04466d656957268304a316"
};

const app = firebase.initializeApp(process.env.REACT_APP_TARGET === "production" ? firebaseConfigProd : firebaseConfig);

const online = process.env.NODE_ENV === "production" || false;

console.log("firebase config: ", process.env.REACT_APP_TARGET);

console.log("Node env: " + process.env.NODE_ENV);
if (!online) {
  firebase.firestore().settings(
    {
      host: "localhost:8080",
      //host: "http:192.168.0.175:8080",
      ssl: false
    });
  console.log("Using local functions emulator")
  firebase.functions().useFunctionsEmulator("http://localhost:5001");
}



/*
firebase.auth().currentUser.getIdTokenResult(true)
.then(res=>console.log("Logged in claims: ", res.claims));
*/

const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
}
const store = createStore(rootReducer, applyMiddleware(thunk));
/*
compose(
  applyMiddleware(thunk),
  //reduxFirestore(firebaseWrapper.config),
  reactReduxFirebase(firebaseWrapper.config)
  )
  );
  */
//const unsubscribe = store.subscribe(() => console.log("State: ", store.getState()))


firebase.auth().onAuthStateChanged((user) => {
  //store.dispatch(setLoggedInUser(user));
  console.log("Setting logged in user: ", user);
  const currentUser = firebase.auth().currentUser;
  if (currentUser) {
    currentUser.getIdTokenResult(true).then(tokRes => {
      store.dispatch(setLoggedInUserClaims(tokRes.claims));
    })
  }
  else {
    store.dispatch(setLoggedInUserClaims(null));
  }
})


const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance // <- needed if using firestore
}


ReactDOM.render(
  <React.StrictMode>

    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <App />
      </ReactReduxFirebaseProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

navigator.serviceWorker.addEventListener('message', event => {
  // event is a MessageEvent object
  //console.log(`The service worker sent me a message: ${event.data}`);
});