import React, { useState } from 'react'
import { useFirebaseConnect, useFirestoreConnect, useFirestore, useFirebase, getFirebase } from 'react-redux-firebase'
import { useParams, Link, useHistory} from 'react-router-dom';
import { useSelector } from 'react-redux';
import {Button, Icon, Breadcrumb, Row, Col, Collection, CollectionItem, Collapsible, CollapsibleItem, Badge} from 'react-materialize';
import {KeyValGrid} from './Util/KeyValGrid';
import LinkCollection from './Util/LinkCollection';
import FTBreadcrumb from './Util/FTBreadcrumb';
import {  UserDataModal, FarmDataModal, ViewUserModal, ConfirmModal_Delete, TempMessageModal } from './Util/ModalForms';
import firebase, { firestore } from 'firebase/app';
import { UserLinkCollection, FarmLinkCollection } from './DynamicLinkCollections';
import {getCamStateColor} from "./Util/inferStyles";
import { getSessionColor, getSessionIsActive } from './Util/sessionUtils';
import { saveAs } from 'file-saver';
import { getFirestore } from 'redux-firestore';
import M, { Toast } from "materialize-css";

export const TenantDetailView = (props) => {

    const history = useHistory();

    const tenantId = props?.tenantPath?.substring(props.tenantPath?.lastIndexOf("/") + 1);
    const [addUserOpen, setAddUserOpen] = useState(false);
    const [viewUserOpen, setViewUserOpen] = useState(null);
    const [addFarmOpen, setAddFarmOpen] = useState(false);
    const [deleteOwnerModalOpen, setDeleteOwnerModalOpen] = useState(false);
    const [gettingDataDump, setGettingDataDump] = useState(false);
    const [downloadUrl, setDownloadUrl] = useState(null);

    //console.log(`Tenant path: ${props.tenantPath} and user id: ${props.user_id}`);
    const camerasStorePath = `${tenantId}-cameras`;

    useFirestoreConnect(() => [
        {
            collection: 'users',
            doc: props.user_id,
            
        },{
            collection: 'tenants',
            doc: props.user_id,
            
        }, // or `todos/${props.todoId}`
        {
            collection: 'tenants',
            doc: props.user_id,
            subcollections: [
                {
                    collection: 'farms',
                }
            ],
            storeAs: "tenantFarms"
        },
        {
            collection: "cameras",
            where:["tenantPath", "==", props.tenantPath],
            storeAs: camerasStorePath
        }
    ])
    
    const tenant = useSelector(({ firestore: { data } }) => data?.tenants && data.tenants[props.user_id]);
    const farms  = useSelector(({ firestore: { ordered } }) => ordered.tenantFarms || [])
    const cameras = useSelector(({firestore: {ordered}}) => ordered[camerasStorePath] || []);
    const user = useSelector(({ firestore: { data } }) => data?.users && data.users[props.user_id]);
    const role = useSelector(state=>state.global.userClaims?.role);
    const firestore = useFirestore();
    const users_derived  = useSelector(({ firestore: { data } }) => data?.tenants && data.tenants[props.user_id]?.users_derived || []);
    
    const farmNamesAndLinks = farms.map(f=>[f.displayName, `/tenants/${props.user_id}/farms/${f.id}`]);
    
    const users = users_derived?.filter(uD=>uD.id !=props.user_id);
    
    const dumpData = ()=>{
        const dumpFnc = getFirebase().functions().httpsCallable("getDataDump");
       // const prefixes = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "A", "B", "C", "D", "E", "F"];
       setGettingDataDump(true);
       M.toast({html:`Trying to compile and download data dump`})
       dumpFnc({tenantId/*, sesPrefix:"4" + prefix*/})
       .then(dumpData=>{
           setGettingDataDump(false);
           
            M.toast({html:`Done compiling and downloading data dump`})
           //console.log(`Dump data with prefix: ${prefix}`, dumpData);
           const blob = new Blob([JSON.stringify(dumpData, null, 2)], {type:"application/json"});
           saveAs(blob, `${tenant.displayName_derived}_dump.json`);
       })
       .catch(e=>{
           M.toast(`Error downloading data dump: ${e}`)
           console.log(`Caught error:`, e);
        })
        .then(()=>{
            setGettingDataDump(false);
       })
       
       /*prefixes.forEach((prefix)=>{

       })
       */
    }
    
    const detailDataArray = [
        ["Name", tenant?.displayName_derived],
        ["Active", tenant?.active ? "Yes" : "No"],
        // ["Created at", new Date(tenant.createdAt.seconds * 1000).toTimeString()],
        //["Owner Id", tenant.ownerId],
    ];

    if(props.role === "master")
    {
        detailDataArray.push(["originalPassword",user?.originalPassword] );
    }

    const canAdd = props.role == "master" || props.role == "tenant" && tenant?.active;

    const breadcrumbNamesAndLinks = props.role == "tenant" ? [["Dashboard", "/"]] :
    [
        ["Dashboard", "/"],
        ["Owners", "/Tenants"],
        [tenant && tenant.displayName_derived, "#"],
    ]

    
    const farmsWithStatesForSessions = farms.map(f=>{
        const sessionStates = Object.entries(f.statesForSessions || {}).map(([key,val])=>{return {...val, ...(f?.statesForCameras?.hasOwnProperty(val.cameraId) ? f?.statesForCameras[val.cameraId] : {})}});
        
        //console.log("Session states: ", sessionStates);
        const colorSummary = sessionStates.reduce((acc, cur)=>{
            const newColor = getSessionColor({cameraState:cur, state:cur, active: getSessionIsActive(cur.category)});
            const elem = acc.find(({color, count})=>color===newColor);
            if(elem === undefined)
                acc.push({color: newColor, count:1});
            else
            {
                elem.count += 1;
            }
            //console.log(`Color: ${newColor} and acc: `, acc);
            return acc;
        }, []);

        return {...f, sessionStates, colorSummary};
    });

    //f.statesForSessions?.map(st=>{
        //return{...st, camState: f.statesForCameras[st.cameraId]}})});
        
        //console.log("farm ses states ", farmsWithStatesForSessions);

    const deleteUserFnc = () => {
        return firestore.collection("users").doc(viewUserOpen).delete()
        .then(setTimeout(()=>setViewUserOpen(false), 500));
    }
        
    const deleteOwnerFnc = () => {
        const tenantName = tenant?.displayName_derived;

        return firestore.doc(`tenants/${tenantId}`).delete()
        .then(()=>{
            M.toast({html:`Deleted tenant '${tenantName?.displayName_derived}'`}, 4000)
            setTimeout(()=>history.goBack(), 1000);
        })
    }
    return (
        <div>
            <FTBreadcrumb namesAndLinks={breadcrumbNamesAndLinks}/>
            <UserDataModal tenantId={tenantId} open={addUserOpen} new close={()=>setAddUserOpen(false)} />
            <ViewUserModal userId={viewUserOpen} onDelete={deleteUserFnc} open={!!viewUserOpen} onClose={()=>setViewUserOpen(null)}/>
            <FarmDataModal open={addFarmOpen} tenantId={tenantId} new close={()=>setAddFarmOpen(false)}/>
            {gettingDataDump && <TempMessageModal/>}
            <div className="container">
                
                <Row>
                    
                    <Col className="desktop" m={6} s={12}>
                        <h4>Owner </h4>
                        <KeyValGrid propAndValuePairArray={detailDataArray} />
                    </Col>
                    <Col m={6} s={12}>
                        <h4>Farms</h4>
                        <Collection>
                        {farmsWithStatesForSessions.map(f=><CollectionItem key={f.id}>
                                <div>
                                    <Link to={`/tenants/${tenantId}/farms/${f.id}`}>{f.displayName}</Link >
                                    {f.colorSummary.map(({color, count})=><Badge key={color} style={{minWidth:"2rem"}} className={`right ${color}`}><span className="white-text">{count}</span></Badge>)}
                                </div>
                            </CollectionItem>)}
                        </Collection>
                        {role === "master" && <Button floating icon={<Icon>add</Icon>} onClick={()=>setAddFarmOpen(true)}/>}
                        {/*<FarmLinkCollection nameAndLinkTuples= {farmNamesAndLinks} tenantId={props.user_id} canAdd={canAdd}/>*/}
                    </Col>
                </Row>
                <Row>
                    <Col m={6} s={12}>
                        <h4>Cameras</h4>
                        <Collection>
                        {cameras.map((cam, i)=><CollectionItem key={i} className={getCamStateColor(cam?.state || {})}>
                                <Link className="white-text truncate" to={`/tenants/${tenantId}/cameras/${cam.id}`}><Row ><Col m={6} style={{overflow:"hidden"}}>{cam.id}</Col><Col m={6}>{cam.state?.executableRevision}</Col></Row></Link >
                            </CollectionItem>)}
                        </Collection>
                        
                    </Col>
                    <Col m={6} s={12}>
                        <h4>Users</h4>
                        <Collection>
                        {
                            users.map(u=><CollectionItem key={u.id}>
                                <div onClick={()=>setViewUserOpen(u.id)} className="text-btn">{u.displayName}</div>
                            </CollectionItem>)
                        }
                        </Collection>
                        <Button floating icon={<Icon>add</Icon>} onClick={()=>setAddUserOpen(true)}/>
                    </Col>
                </Row>

                {props.role === "master" &&
                    <div>
                        <div style={{height: "5em"}}/>
                        <Button onClick={()=>dumpData()}>Get Data Dump</Button>
                        <div className={"btn-link inline"}>
                            <Button small className="right red" onClick={()=>setDeleteOwnerModalOpen(true)}>
                                Delete Owner
                                <Icon right>
                                    delete
                                </Icon>
                            </Button>
                        </div>
                    </div>
                }

            </div>
            <ConfirmModal_Delete
                open={deleteOwnerModalOpen}
                onConfirm={deleteOwnerFnc}
                onCancel={()=>setDeleteOwnerModalOpen(false)}
                contentType="Owner"
                contentName={tenant?.displayName_derived}/>
        </div>
    )
}

export const TenantMasterDetail = (props) => {
    
    
    const {tenantId} = useParams();
    
    //console.log("Params: ", tenantId);
    return (<TenantDetailView tenantPath={`tenants/${tenantId}`} user_id={tenantId} role="master"/>);
}
