import React, { useState } from 'react'
import { useFirestoreConnect } from 'react-redux-firebase'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import firebase, { firestore } from 'firebase/app';
import FTBreadcrumb from './Util/FTBreadcrumb';
import { useSelector } from 'react-redux';
import { KeyValGrid, ObjectGrid } from './Util/KeyValGrid';
import { useInterval } from './Util/useInterval';
import { Collapsible, CollapsibleItem, Row, Col, Collection, CollectionItem, Button, Icon, Divider, Tab, Modal } from 'react-materialize';
import { Link } from 'react-router-dom';
import M from "materialize-css";
import { vars, camStates } from "./../vars";
import { getCamStateString } from "./Util/dataTransformation";
import { SubmitBtn, DarkOverlay, ConfirmModal, ConfirmModal_Delete, CameraDataModalWithFirestore } from "./Util/ModalForms";
import { CameraEventsConnected } from "./CameraEvents";
import { getCamStateColor } from "./Util/inferStyles";

const FaultsSection = ({ faults, timeNow, eventId }) => {

    const [isDismissing, setIsDismissing] = useState(false);

    //console.log(`Faults: `, faults);
    const dismiss = (ev) => {
        //console.log("Dismis: ", ev);
        setIsDismissing(true);
        const firestore = firebase.firestore();
        firestore.doc(`cameras/${ev.cameraId}/events/${ev.id}`).update({ dismissed: true })
            .catch(e => console.log("Error dismissing fault event: ", e))
            .then(() => setIsDismissing(false));
    }

    return (faults.length > 0 &&
        <div>
            <h5 style={{ display: "inline" }}>Warnings</h5>
            <Icon className="icon-red">
                error
            </Icon>
            <Collection>
                {faults.map((fE, i) => {
                    const faultCodeInt = typeof fE.faultCode === "number" ? fE.faultCode : parseInt(fE.faultCode, 16); //Older charger code, did not parse hex-value to int, so we do it here

                    return <CollectionItem key={i}>
                        <Row style={{ width: "100%" }}>
                           <Col s={5}>{vars.FAULT_TYPES[faultCodeInt] ?? faultCodeInt}</Col>
                            <Col s={4}>{new Date(fE.timestamp).toLocaleString("da-DK") || "?"}</Col>
                            <div className="right-align"><Button onClick={() => dismiss(fE)}>Dismiss</Button></div>
                        </Row>
                    </CollectionItem>
                })}
            </Collection>
            {isDismissing ? <DarkOverlay spinner /> : null}
        </div>
    );
}

const EventHeader = (props) => {

    return (props.timestamp == 0 ?
        <Row style={{ width: "100%" }} className="center-align">
            <Col m={2}>{props.name}</Col>
            <Col m={10}>None received</Col>
        </Row> :
        <Row style={{ width: "100%", height: "1em" }} className="centser-align">
            <Col m={2}>{props.name}</Col>
            <Col m={2}>{"~" + (Math.floor((props.timeNow - props.timestamp) / 60_000) + " mins ago" || "?")}</Col>
            <Col m={2}>Battery: {props.battery || "?"}%</Col>
            <Col m={4}>Type: {props?.commandString || "?"}</Col>
            <Col m={2}>Signal: {props.signalStrength || "?"} db</Col>
        </Row>

    )
}

const EventDetail = (props) => {

    const valsWithTimestrings = props.keyValTupleArray;

    //console.log("EVent detail props: ", props);
    for (var i = 0; i < valsWithTimestrings.length; i++) {
        const keyAndVal = valsWithTimestrings[i];
        //console.log("Key: ", key);
        //console.log("Val: ", val);
        if (keyAndVal[0].includes("timestamp")) {
            if (keyAndVal[0].includes("_gaps")) {
                const gapArr = keyAndVal[1];
                keyAndVal[1] = `${gapArr[0]} : ${gapArr[1]} : ${gapArr[2]}`
            }
            else {
                const date = new Date(keyAndVal[1])
                keyAndVal[1] = date.toLocaleString("en-GB") + ":" + date.getMilliseconds();
            }
        }

        /*if (keyAndVal[0].includes("timestamp")) {
            keyAndVal[1] = new Date(keyAndVal[1]).toLocaleString("en-GB");
        }*/
    };

    const arrLength = valsWithTimestrings.length;
    const firstLength = Math.ceil(arrLength / 2);
    const first = valsWithTimestrings.slice(0, firstLength);
    const last = valsWithTimestrings.slice(firstLength, arrLength);


    return (

        <Row className="centser-align">
            <Col m={6}>
                <KeyValGrid propAndValuePairArray={first} />
            </Col>
            <Col m={6}>
                <KeyValGrid propAndValuePairArray={last} />
            </Col>
        </Row>
    )
}

export const CameraDetail_Master = () => {

}

const ConfirmDelete = ({ name }) => <div className="center-align"><h5>Delete Camera:</h5><h4>{name}</h4><h5>?</h5></div>

export const CameraDetailView = () => {

    const history = useHistory();
    const { cameraId, tenantId, farmId } = useParams();

    const [timeNow, setTimeNow] = useState(Date.now());

    const [deleteModalOpen, setdeleteModalOpen] = useState(false);
    const [editCamModalOpen, setEditCamModalOpen] = useState(false);
    const [showEventsModalOpen, setshowEventsModalOpen] = useState(false);

    let location = useLocation();
    //console.log("Use interval: ", useInterval);
    useInterval(() => {
        setTimeNow(Date.now())
    }
        , 60_000);

    useFirestoreConnect(() => [
        {
            collection: "cameras",
            doc: cameraId
        },
        {
            collection: "cameras",
            doc: cameraId,
            subcollections: [
                {
                    collection: "events",
                    where: [['commandString', '==', `FAULT`]],
                }
            ],
            storeAs: "faultEvents"
        }
    ]);

    const role = useSelector(state => state.global.userClaims.role);

    //const cameras = useSelector(({ firestore: { data } }) => data?.cameras);

    const camData = useSelector(({ firestore: { data } }) => data?.cameras && data?.cameras[cameraId]);

    //console.log("camData ", camData);
    const faultEvents = useSelector(({ firestore: { ordered } }) => ordered?.faultEvents) || [];

    //console.log("Ikke disse", [vars.faultCodes.QUEUE_FULL, vars.faultCodes.QUEUE_LARGE])
    //console.log("har disse", faultEvents.map(fE=>fE.faultCode));
    //Filter away all dismissed and queue-faults (only if not master)

    const faultEventsActive = faultEvents.filter(fE =>
        !fE.dismissed &&
        ([vars.faultCodes.LOW_BATTERY].includes(fE.faultCode) || role === "master")).sort((a,b)=>b.timestamp - a.timestamp);

    const latestCamEvent = Object.entries(camData?.state?.latestEvent_cam || {});

    const latestServerEvent = Object.entries(camData?.state?.latestEvent_server || {});
    const latestSessionEvent = Object.entries(camData?.state?.latestSessionEvent_cam || {});


    const lastCamTimestamp = camData?.state?.latestEvent_cam?.timestamp;

    const lastServerTimestamp = camData?.state?.latestEvent_server?.timestamp_server;

    const lastFaultCamTimestamp = faultEvents.reduce((acc, cur) => cur.timestamp > acc ? cur.timestamp : acc, 0);

    const camState = camData?.state || "?";

    //console.log("Cam state: ", camState);
    const timeSinceContact = lastServerTimestamp ? Math.max(0, Math.floor((timeNow - lastServerTimestamp) / 60_000)) : Number.MIN_SAFE_INTEGER;

    const battery = camData?.state?.latestEvent_cam?.battery || -1;

    const signalStrength = camData?.state?.latestEvent_cam?.signalStrength || 0;

    const detailDataArray = [
        ["Id", cameraId || "?"],
        ["Owner", camData?.tenant_derived?.displayName || "?", camData?.tenantPath],
        ["Farm", camData?.farm_derived?.displayName, camData?.farmPath],
        ["battery", battery >= 0 ? battery + "%" : "?"],
        ["last contact", timeSinceContact > Number.MIN_SAFE_INTEGER ? "~ " + timeSinceContact + " minutes" : "?"],
        ["Signal strength", signalStrength != 0 ? signalStrength + " db" : "?"],
        ["last fault", lastFaultCamTimestamp ? "~ " + Math.floor((timeNow - lastFaultCamTimestamp) / 60_000) + " minutes" : "none"],
        ["State", camState.camState],
        ["Connected", camState.connection ? "Yes" : "No"]
    ]

    if (role === "master") {
        detailDataArray.push(["Executable", camState.executableRevision])
        detailDataArray.push(["FarrowSetup.xml", camState.setupFileHash])
        detailDataArray.push(["Charger", camState.chargerVersion])
    }

    const classesForIndices = [
        [3, battery <= 20 && battery >= 0 ? <Icon className={battery <= 10 ? "icon-red" : "icon-yellow"}>error</Icon> : ""],
        [4, timeSinceContact >= 0 && timeSinceContact >= 5 ? <Icon className={camState.connected >= 10 ? "icon-red" : "icon-yellow"}>error</Icon> : ""],
        [5, signalStrength <= -30 ? <Icon className={signalStrength <= -40 ? "icon-red" : "icon-yellow"}>error</Icon> : ""],
        [7, getCamStateColor(camState)],
        [8, !camState.connection && ![camStates.off, camStates.unknown].includes(camState.camState) && <Icon className="icon-red">error</Icon>]
    ];
    //console.log("details: ", detailDataArray);

    //console.log("details type: ", typeof detailDataArray);

    const breadcrumbNamesAndLinks = role == "master" ? tenantId ? farmId ?
        [
            ["Dashboard", "/"],
            ["Owners", "/Tenants"],
            [camData?.tenant_derived?.displayName, `/Tenants/${tenantId}`],
            [camData?.farm_derived?.displayName || "", `/Tenants/${tenantId}/farms/${farmId}`],
            [cameraId + " (camera)", "#"]
        ] :
        [
            ["Dashboard", "/"],
            ["Owners", "/Tenants"],
            [camData?.tenant_derived?.displayName, `/Tenants/${tenantId}`],
            [cameraId + " (camera)", "#"]
        ] :
        [
            ["Dashboard", "/"],
            ["Cameras", "/cameras"],
            [cameraId + " (camera)", "#"]
        ] :
        [
            ["Dashboard", "/"],
            [cameraId + " (camera)", "#"]
        ];


    const deleteCamFnc = () => {
        return firebase.firestore().doc(`cameras/${cameraId}`).delete()
            .then(() => {

                M.toast({ html: `Deleted camera '${cameraId}'` }, 4000)
                setdeleteModalOpen(false);
                history.goBack();
            })
    }

    //console.log("Latest cam event: ", camData?.state?.latestEvent_cam);
    //console.log(`edit open: ${editCamModalOpen} and cams data: `, camData);
    return (
        <div>
            <FTBreadcrumb namesAndLinks={breadcrumbNamesAndLinks} />
            <div className="container">
                <div className="valign-wrapper">
                    <h4 style={{}}>Camera</h4>
                    <span style={{ width: "3em" }} />
                    {camData && <Button floating onClick={() => setEditCamModalOpen(true)}><Icon>edit</Icon></Button>}
                </div>

                <KeyValGrid propAndValuePairArray={detailDataArray} classesForIndices={classesForIndices} />


                <FaultsSection faults={faultEventsActive} timeNow={timeNow} />
                {role != "master" ? "" : <div className="desktop">

                    <h5>Last Events</h5>
                    <Collapsible accordion={false}>
                        <CollapsibleItem className={"dense-data"} header={<EventHeader {...{ ...camData?.state?.latestEvent_cam, name: "Camera", timeNow: timeNow }} />}>
                            {camData?.state?.latestEvent_cam?.timestamp > 0 ? <EventDetail keyValTupleArray={latestCamEvent} /> : null}

                        </CollapsibleItem>
                        <CollapsibleItem className={"dense-data"} header={<EventHeader {...{ ...camData?.state?.latestEvent_server, name: "Server", timeNow: timeNow }} />}>
                            {camData?.state?.latestEvent_server?.timestamp > 0 ? <EventDetail keyValTupleArray={latestServerEvent} /> : null}

                        </CollapsibleItem>
                        <CollapsibleItem className={"dense-data"} header={<EventHeader {...{ ...camData?.state?.latestSessionEvent_cam, name: "Session", timeNow: timeNow }} />}>
                            {camData?.state?.latestSessionEvent_cam?.timestamp > 0 ? <EventDetail keyValTupleArray={latestSessionEvent} /> : null}

                        </CollapsibleItem>
                        {/* <CollapsibleItem header={<EventHeader {...{...latestCamEvent, name: "Server"}}/>}>
                        <KeyValGrid propAndValuePairArray={Object.entries(latestServerEvent)}/>
                        </CollapsibleItem>
                        <CollapsibleItem header={<EventHeader {...{...latestSessionEvent, name: "Session"}}/>}>
                        <KeyValGrid propAndValuePairArray={Object.entries(latestSessionEvent)}/>
                    </CollapsibleItem> */}
                    </Collapsible>
                </div>}
                <div className="desktop" >
                    {role == "master" &&
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            }}>
                            
                            <Button onClick={() => setshowEventsModalOpen(true)}>
                                See all camera events
                                <Icon right>
                                        arrow_forward
                                </Icon>
                            </Button>
                            <Button className="center-align" onClick={() => history.push(`${location.pathname}/sessions`)}>
                                See camera sessions
                                <Icon right>
                                        arrow_forward
                                </Icon>
                            </Button>
                            <Button className="right red" onClick={() => setdeleteModalOpen(true)}>
                                <div className={"btn-link"}>
                                    Delete Camera
                                    <Icon right>
                                                delete
                                    </Icon>
                                </div>
                            </Button>
                    
                        </div>}
                        <ConfirmModal_Delete
                            open={deleteModalOpen}
                            onConfirm={deleteCamFnc}
                            onCancel={() => setdeleteModalOpen(false)}
                            contentType="camera"
                            contentName={cameraId} />
                        {showEventsModalOpen &&
                            <Modal options={{ onCloseEnd: () => setshowEventsModalOpen(false) }} className="events-modal" open={showEventsModalOpen}>
                                <CameraEventsConnected cameraId={cameraId} />
                            </Modal>
                        }
                    {editCamModalOpen && camData && <CameraDataModalWithFirestore
                        open={editCamModalOpen}
                        onClose={() => setEditCamModalOpen(false)}
                        defaultTenantPath={camData?.tenantPath}
                        defaultFarmPath={camData?.farmPath}
                        fixedTenantId={role !== "master" && tenantId}
                        fixedCamId={cameraId}
                        existingFarmPath={camData?.farmPath} />}

                </div>
            </div>

        </div>
    );
}