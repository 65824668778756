import React, { useState, Component, createElement, useEffect } from 'react'
import { useFirebaseConnect, useFirestoreConnect, getFirebase } from 'react-redux-firebase'
import firebase, { firestore } from 'firebase/app';
import { useParams, useHistory, NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Icon, Modal, Breadcrumb, Select, Row, Col, Collection, CollectionItem, Collapsible, CollapsibleItem, Divider, Checkbox } from 'react-materialize';
import { KeyValGrid } from './Util/KeyValGrid';
import LinkCollection from './Util/LinkCollection';
import FTBreadcrumb from './Util/FTBreadcrumb';
import { UserNavbar } from "./navvbar/navbar";
import { getColorForReactionTime } from "./Util/inferStyles";
import { useInterval } from './Util/useInterval';
import { createSelector } from 'reselect'
import { ModalForm, ConfirmModal_Delete } from './Util/ModalForms';
import { UserLinkCollection } from './DynamicLinkCollections';
import { MultiSelectModal } from "./Util/MultiSelectModal"
import { SessionEventsConnected } from "./SessionEvents";
import { CameraEventsConnected } from "./CameraEvents";
import M from "materialize-css";
import { getFirestore } from 'redux-firestore';
import { camStates, vars } from "../vars";
import * as manualEvents from "../modules/events";
import { sortSessionsByAlarmed, activeInactiveReducerFnc, getSessionColor, insertDividers } from "./Util/sessionUtils"
import { DarkOverlay, EndSessionModal } from "./Util/ModalForms";

import { Navbar } from 'react-materialize';


const ArchiveModal = ({ sessionId, open, onClose, onArchive }) => {
    const [submitting, setSubmitting] = useState(false);
    const [archiveAllOlder, setArchiveAllOlder] = useState(false);

    const performArchive = () => {
        setSubmitting(true);
        console.log("perform archive: ", sessionId)
        return onArchive(sessionId, archiveAllOlder)
            //Promise.resolve()
            .then(() => {
                //setSubmitting(false);
            })
            .catch(e => M.toast({ html: `something went wrong archiving: ${e}` }))
    }
    return (
        submitting && <Modal
            header={`Archive Session(s) sessionId ${sessionId}`}
            className={submitting ? "semi-transp" : ""}
            open={open}
            options={{ onCloseEnd: onClose }}
            actions={[
                <Button disabled={submitting} className="left" flat modal="close" node="button" waves="green">Cancel</Button>,
                <Button disabled={submitting} node="button" waves="light" type="submit" onClick={performArchive}>
                    <Icon>archive</Icon>
                </Button>
            ]}
        >
            <Checkbox id="hans" checked={archiveAllOlder} value="includeOlder"
                label="Include all older sessions" onChange={() => setArchiveAllOlder(!archiveAllOlder)} />
            {submitting ? <DarkOverlay spinner /> : null}
        </Modal>
    )
}

const SessionHeader = ({ penNumber, active, cameraState, state, colorClass, timeNow, reactionMean }) => {
    //console.log("Session props: ", props);
    const minsSinceBirth = state?.latestBirthTimestamp ? Math.max(0, Math.floor((timeNow - state?.latestBirthTimestamp) / 60_000)) : undefined;

    const mins = minsSinceBirth && minsSinceBirth % 60;
    const hours = minsSinceBirth && Math.floor(minsSinceBirth / 60);
    const sinceString = minsSinceBirth === undefined ? "none" : `${hours.toString().padStart(2, "0")}:${mins.toString().padStart(2, "0")}`;

    const bat = state?.battery;

    const camState = cameraState?.camState;
    const batIconName = bat < 0 ? "battery_unknown" : bat <= 15 ? "battery_alert" : camState == camStates.charging ? "battery_charging_full" : "";
    const batteryIcon = batIconName && <Icon>{batIconName}</Icon>;


    const latestEventTime = state?.latestEventTimestamp && new Date(state?.latestEventTimestamp).toLocaleTimeString("en-GB");
    return (

        <Row style={{ width: "100%", height: "1em" }} className="">
            <Col  s={3} className="truncate center-align" >{penNumber}</Col>
            <Col className="valign-wrapper center-align" s={1}>{batteryIcon}</Col>


            {active ?
                <Col className="truncate center-align" s={4}>{sinceString}</Col> :
                <Col className="truncate center-align" s={4}>{latestEventTime}</Col>
            }

            {active ? <Col className=" center-align" s={1}>{!cameraState?.connection && <Icon>signal_cellular_connected_no_internet_4_bar</Icon>}</Col> : <Col s={1}/>}

            {/* {active && <Col s={2}>{state?.numberInLitter}</Col>} */}
            {/* <Col s={1} className={colorClass}></Col> */}
            <Col s={3} className="truncate center-align" >{reactionMean >= 0 && `${reactionMean} mins`}</Col>
        </Row>
    )
}

const SectionHeader = ({ heading, empty, active }) => {
    return (
        <div className="section-header">
            <h5 className="center-align">{heading}</h5>
            {empty ? <div className="center-align">none</div> : <Row style={{ width: "100%", height: "1em" }} >
                <Col s={3} className="truncate center-align" >Pen</Col>
                {active ? <Col className="truncate center-align" offset="s1" s={4}>Time since birth</Col> : <Col s={4} className="truncate center-align" offset="s1">active</Col>}
                <Col /*offset="s1"*/ s={3} offset="s1" className="truncate center-align" >Reaction time</Col>
                {/* <Col s={3}>{active && "Birth events"}</Col> */}
                {/* <Col s={1}>State</Col> */}
            </Row>}
        </div>)
}


const SessionDetail = ({ sowNumber, onArchive, cameraState, camId, state, camSessionId, active, getOlderIds }) => {

    const sesCats = vars.sessionCategories;

    const attentionText = [sesCats.INCOMPLETE_NO_END_NEWER_CAM_EVENTS,
    sesCats.INCOMPLETE_NO_END_NEWER_SESSION].includes(state.category) ?
        "Not ended" : undefined;

    const [showArchiveDialogue, setShowArchiveDialogue] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const clickEnd = async () => {
        setSubmitting(true);
        const firestore = firebase.firestore();
        const evRef = firestore.collection(`cameras/${camId}/events`).doc();

        const ev = manualEvents.getManualEndEvent({ cameraId: camId, sessionId: camSessionId, pigletsBornAlive: -1, pigletsBornDead: -1});
        console.log("dnew event id: : ", evRef.id);
        console.log("New ev: ", ev);
        //console.log("detail props: ", props);
        await evRef.set(ev)
            .then(() => {
                setSubmitting(false);
                M.toast({ html: "Manual end inserted" });
            })
            .catch((e) => {
                setSubmitting(false);
                console.log("Manual end error: ", e);
            })
    }
    const archiveFnc = () => {
        setShowArchiveDialogue(true);
        return onArchive(camSessionId, false);
    }

    const durationMillis = state?.latestEventTimestamp && state?.firstEventTimestamp ? state?.latestEventTimestamp - state?.firstEventTimestamp : -1;

    const durationString = durationMillis < 0 ? "?" : `
            ${Math.floor(durationMillis / (60 * 60_000))}h ${Math.floor(durationMillis / (60_000)) % 60}m`;

    const isExperimentControlGroup = state.isExperimentControlGroup;
    //console.log("cameraState: ", cameraState);
    return (
        <div>
            {/*showArchiveDialogue && onArchive && <ArchiveModal onArchive={archiveFnc} sessionId={camSessionId}
             open={showArchiveDialogue} onClose={()=>setShowArchiveDialogue(false)}/>*/}
            {showArchiveDialogue ? <div className="valign-wrapper" style={{ height: "10vh" }}>
                <span className="center">Archiving...</span>
            </div> :
                <Row className="dense-data">
                    <Col s={7} m={6}>
                        <Row>

                            <Col s={5}>Sow</Col><Col s={7}>{isExperimentControlGroup ? "?" : sowNumber}</Col>
                        </Row>
                        <Row>
                            <Col s={5}>Session</Col><Col s={7}>{camSessionId}</Col>
                        </Row>
                    </Col>
                    <Col s={5} m={6}>
                        <Row>
                            <Col s={8}>Battery</Col><Col s={4}>{state?.battery}%</Col>
                        </Row>
                        <Row>
                            {/* <Col s={5}>State</Col><Col s={7}>{cameraState?.camState}</Col> */}
                            <Col s={8}>Birth events</Col><Col s={4}>{isExperimentControlGroup ? "?" : state?.numberInLitter}</Col>
                        </Row>
                    </Col>
                    <Col s={12}>
                    <Row><Col s={8}>Farrowing started</Col>
                        <Col s={4}>
                            {state?.firstBirthTimestamp === undefined ? "No" : new Date(state?.firstBirthTimestamp).toLocaleString("en-GB")}
                        </Col>
                    </Row>
                    
                    <Row>
                        <Divider></Divider>
                    </Row>
                    <Row>
                        <Col s={8}>{(active ? "Assisted" : "Confirmed")} live / dead</Col>
                        <Col s={4}>
                            {state?.pigletsConfirmedAlive >= 0 ? state?.pigletsConfirmedAlive : "-"} / {state?.pigletsConfirmedDead >= 0 ? state?.pigletsConfirmedDead : "-"}
                        </Col>
                    </Row>
                    
                    {!active && <Row>
                        <Col s={8}>Duration</Col>
                        <Col s={4}>{isExperimentControlGroup ? "?" : durationString}</Col>
                    </Row>}
                    {attentionText &&
                        <Row >

                            <Col m={8}>Attention</Col>
                            <Col m={4} className="valign-wrapper">{attentionText}<Icon className="icon-red">error</Icon></Col>

                            <Button disabled={submitting} small onClick={clickEnd}> Insert End</Button>
                        </Row>
                    }
                    </Col>
                    {/*!attentionText && !active && <Button onClick={archiveFnc} className="right">Archive</Button>*/}
                </Row>}
        </div>
    )
}

export const SessionsViewWithDataSubscription = () => {
    const { tenantId, farmId } = useParams();


    const mSecsPrHour = 1000 * 60 * 60;
    const hoursAgo = 72;
    const timestampSomeHoursAgo = Math.floor(Date.now() / mSecsPrHour - hoursAgo) * mSecsPrHour;
    //console.log("Hour: " + timestampSomeHoursAgo);

    const user_id = useSelector(state => state.global.userClaims.user_id);

    useFirestoreConnect([
        {
            collection: 'tenants',
            doc: tenantId,
            subcollections: [
                {
                    collection: 'farms',
                    doc: farmId,
                }
            ],
            storeAs: "farmData",
        },
        {
            collection: 'tenants',
            doc: tenantId,
            subcollections: [
                {
                    collection: 'farms',
                    doc: farmId,
                    subcollections: [
                        {
                            collection: 'sessions',
                            where: ["state.firstEventTimestamp", ">", timestampSomeHoursAgo],
                            orderBy: ['state.firstEventTimestamp', "desc"]
                        }
                    ]
                }
            ],
            storeAs: "farmSessions",
        },
        {
            collection: 'cameras',
            where: [['farmPath', '==', `tenants/${tenantId}/farms/${farmId}`]],
            storeAs: "farmCameras",
        },
        {
            collection: "users",
            doc: user_id,
            storeAs: "userData"
        }
    ])

    const farmData = useSelector(({ firestore: { data } }) => data.farmData)
    const userData = useSelector(({ firestore: { data } }) => data.userData)


    return (<div>

        <UserNavbar farmPaths_derived={userData?.farmPaths_derived} />
        <SessionsViewConnected reactionTime={farmData?.activeReactionTimesMean} />
    </div>
    )
}

export const SessionsViewConnected = (props) => {
    const { tenantId, farmId } = useParams();

    //console.log("Focus id: ", props.focusedId);
    const [timeNow, setTimeNow] = useState(Date.now());

    const user_id = useSelector(state => state.global.userClaims.user_id);

    const [archiving, setArchiving] = useState(false);
    //console.log("User id: ", user_id);
    useInterval(() => {
        setTimeNow(Date.now())
    }
        , 60_000);


    const sessionsActiveInactive = useSelector(({ firestore: { ordered } }) => {

        const newSes = ordered.farmSessions
            //Sort session with alarmed first
            ?.slice()?.sort(sortSessionsByAlarmed)
            //Add camera-field to all elements
            ?.map(ses => {
                const reactionMean = Math.floor(ses.reactionTimes?.length <= 0 ? -1 : ses?.reactionTimes?.reduce((prev, cur) => prev + cur, 0) / ses.reactionTimes?.length / 60);
                return { ...ses, reactionMean, cameraState: ordered.farmCameras?.find(cam => cam.id == ses.camId)?.state }
            })
            //Reduce into sessionsObj
            .reduce(activeInactiveReducerFnc, { active: [], inactive: [] });
        //newSes.sort((e1, e2)=>e1.state.category == vars.sessionCategories.ALARMED && e2.state.category != vars.sessionCategories.ALARMED)
        return newSes;
    });

    const performArchive = (sesId, allOlder) => {
        console.log("Performing archive for: ", sesId);
        //setArchiving(true);
        const archiveFnc = getFirebase().functions().httpsCallable("archiveSessions");
        const sesSelected = sessionsActiveInactive?.inactive?.find(ses => ses.camSessionId === sesId);
        if (sesSelected) {
            const sessionsToArchie = !allOlder ?
                [sesId] :
                sessionsActiveInactive.inactive.filter(ses => ses.state.latestEventTimestamp <= sesSelected.state.latestEventTimestamp).map(ses => ses.camSessionId);

            console.log(`Archiving ${sesId} and all older ${allOlder}: `, sessionsToArchie);
            return Promise.all(sessionsToArchie.map(ses => {
                return archiveFnc(`tenants/${tenantId}/farms/${farmId}/sessions/${ses}`)
                //.then(()=>M.toast({html:`Archived session: ${ses}`}))
            }));
        }
        //setArchiving(false);
        return Promise.resolve();
    }

    const extractTimestamp = ses => ses?.state?.latestEventTimestamp;
    const extractDate = (ses) => ses && new Date(extractTimestamp(ses)).toLocaleDateString("en-GB");
    const createDateDivider = (dateStr, i) => {
        return (<li key={dateStr + "_" + i} >
            <Row className="session-date-divider">
                <Col s={5}></Col><Col s={1}>{dateStr}</Col>
            </Row>
        </li>)
    };
    const createElem = (ses, i) => {
        //console.log("Ses: ", ses);
        const colorClass = getSessionColor(ses);

        const focusStyle = props.focusedId == ses.camSessionId ? { position: "relative", zIndex: "1000" } : {};

        return (
            ses?.state?.isExperimentControlGroup ?
                <CollapsibleItem id={ses.id}
                    style={focusStyle}
                    bodyClassName="dense-data"
                    key={ses.camSessionId}
                    headerClassName={"less-height " + colorClass}
                    header={<Row><Col>Kontrol</Col><Col>pen:{ses.penNumber}</Col></Row>}>
                    <SessionDetail onArchive={performArchive} timeNow={timeNow} {...ses} />
                </CollapsibleItem>
                :

                <CollapsibleItem id={ses.id}
                    style={focusStyle}
                    bodyClassName="dense-data"
                    key={ses.camSessionId}
                    headerClassName={"less-height " + colorClass}
                    header={<SessionHeader timeNow={timeNow} {...ses} />}>
                    <SessionDetail onArchive={performArchive} timeNow={timeNow} {...ses} />
                </CollapsibleItem>
        )
    }

    //console.log("Act / inact: ", combinedList);
    //const activeWithDividers =  insertDividers(sessionsActiveInactive?.active || [] , extractTimestamp, extractDate, createDateDivider, createElem);
    const activeNoDividers = sessionsActiveInactive?.active?.filter(ses => !ses.state.isExperimentControlGroup)?.map((ses, i) => createElem(ses, i)) || [];
    const activeExperiementCOntrolGroupNoDividers = sessionsActiveInactive?.active?.filter(ses => ses.state.isExperimentControlGroup)?.map((ses, i) => createElem(ses, i)) || [];
    //const activeNoDividers = sessionsActiveInactive?.active?.map((ses, i)=>createElem(ses, i)) || [];

    //console.log("Active No Div: ", activeNoDividers);

    const inactiveWithDividers = insertDividers(sessionsActiveInactive?.inactive || [], extractTimestamp, extractDate, createDateDivider, createElem);

    //const combinedList = [{heading:"Active", active:true, empty:!sessionsActiveInactive?.active?.length > 0}, ...sessionsActiveInactive?.active || [], {heading:"Inactive", active:false, empty: !sessionsActiveInactive?.inactive?.length > 0}, ...sessionsActiveInactive?.inactive || []];

    const combinedList = [
        <SectionHeader key={"active"} heading="Active" empty={activeNoDividers?.length == 0} active={true} />,
        ...activeNoDividers,
        ...activeExperiementCOntrolGroupNoDividers,
        props.showInactive ? <SectionHeader key={"inactive"} heading="Inactive" empty={inactiveWithDividers.length == 0} active={false} /> : null,
        ...(props.showInactive ? inactiveWithDividers : [])];


    //console.log("WithDividers: ", inactiveWithDividers);
    //console.log("From: ", sessionsActiveInactive?.inactive);

    return (<div>
        {/* <UserNavbar farmPaths_derived={userData?.farmPaths_derived}/> */}
        {props.reactionTime >= 0 && <div className="reaction-time right">Reaction time ~ <span style={{ display: "inline" }} className={`${getColorForReactionTime(props.reactionTime)}`}>{((props.reactionTime ?? 0) / 60).toFixed(1)}</span>mins</div>}

        <Collapsible className={"littlePadding ressemble-mobile"} popout accordion={false}>
            {combinedList}
        </Collapsible>
    </div>)

}