import React, { useState } from 'react'
import { Button, Icon, Breadcrumb, Row, Col, Card } from 'react-materialize';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFirebaseConnect, useFirestoreConnect } from 'react-redux-firebase'
import LinkCollection from './Util/LinkCollection';

import { UserDataModal, FarmDataModal, ApiKeyDataModal } from './Util/ModalForms';

export const DynamicLinkCollection = ({ nameAndLinkTuples, canAdd, onClickAdd }) => {

    const [addOpen, setAddOpen] = useState(false);

    return (
        <div>
            <LinkCollection nameAndLinkPairArray={nameAndLinkTuples} />
            <div></div>
            {canAdd ? <Button floating icon={<Icon>add</Icon>} onClick={onClickAdd} /> : null}
        </div>
    );
}


export const UserLinkCollection = (props) => {

    const [addUserOpen, setAddUserOpen] = useState(false);
    return (<div>
        <UserDataModal tenantId={props.tenantId} open={addUserOpen} new close={() => setAddUserOpen(false)} />
        <DynamicLinkCollection {...props} onClickAdd={() => { setAddUserOpen(true) }} />
    </div>);
}


export const FarmLinkCollection = (props) => {

    const [addFarmOpen, setAddFarmOpen] = useState(false);
    return (<div>
        <FarmDataModal open={addFarmOpen} tenantId={props.tenantId} new close={() => setAddFarmOpen(false)} />
        <DynamicLinkCollection {...props} onClickAdd={() => { setAddFarmOpen(true) }} />
    </div>);
}

export const TenantLinkCollection = (props) => {

    const [addTenantOpen, setAddTenantOpen] = useState(false);
    return (<div>
        <UserDataModal tenantId={props.tenantId} isTenant open={addTenantOpen} new close={() => setAddTenantOpen(false)} />
        <DynamicLinkCollection {...props} onClickAdd={() => { setAddTenantOpen(true) }} />
    </div>);
}

export const ApiKeyLinkCollection = (props) => {

    const [addTenantOpen, setAddTenantOpen] = useState(false);
    return (<div>
        <ApiKeyDataModal open={addTenantOpen} new close={() => setAddTenantOpen(false)} />
        <DynamicLinkCollection {...props} onClickAdd={() => { setAddTenantOpen(true) }} />
    </div>);
}
