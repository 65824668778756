import React, { Component } from 'react';
import {connect, useSelector} from "react-redux";
import { useFirebase, useFirebaseConnect, isLoaded, isEmpty, useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { firestore } from 'firebase';

export default function TestContent({farms}) {
    //console.log(farms);
    
    const fireStore = useFirestore();
    
    const firebase = useFirebase();
    
    /*
    const curUser =  firebase.auth().currentUser;
    if(curUser)
    {
        curUser.getIdTokenResult(true)
        .then((idTokenResult) => {
            console.log("claims: ", idTokenResult?.claims);
        })
    }
*/
   
    
  
    
    return (  
        <div>
            <h1>TestContent</h1>
        </div>
    )
}

