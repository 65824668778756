import React from 'react'
import { Breadcrumb } from 'react-materialize'
import { Link } from 'react-router-dom'

const shorten = origStr=>origStr?.substring(0, 10);

export default function FTBreadcrumb({namesAndLinks}) {
    //console.log("breadcrumb names and links: ", namesAndLinks);
    const namesAndLinksMaxTwo = namesAndLinks.slice(-2).map(nAL=>[nAL[0], nAL[1]]) || [];

    namesAndLinksMaxTwo.forEach(element => element[0] = shorten(element[0]));

    //console.log("Names and links: ", namesAndLinks);
    //console.log("Max Two: ", namesAndLinksMaxTwo);
    if(namesAndLinks.length > 2)
    {
        namesAndLinksMaxTwo[0][0] = "..." + namesAndLinksMaxTwo[0][0];
    }
    
    return (
        <div >
            <Breadcrumb className="teal desktop" cols={12}>
                {namesAndLinks.map(mAL=>{
                    return (
                        <Link className="breadcrumb-elem" key={`${mAL[0]}${mAL[1]}`}to={mAL[1]}>
                            <span className="breadcrumb-elem">
                                {mAL[0]}
                            </span>
                        </Link>
                    )
                })}
            </Breadcrumb>
            
            <Breadcrumb style={{whiteSpace:"nowrap"}} className="teal mobile"  cols={12}>
                {namesAndLinksMaxTwo.map(mAL=>{
                    return (
                        <Link className="breadcrumb-elem" key={`${mAL[0]}${mAL[1]}`}to={mAL[1]}>
                            <span className="breadcrumb-elem">
                                {mAL[0]}
                            </span>
                        </Link>
                    )
                })}
            </Breadcrumb>
        </div>
    )
}
