import firebase from "firebase";
import { useFirestoreConnect } from "react-redux-firebase"
import { useHistory, useLocation, useParams } from "react-router"
import { withFirestore } from 'react-redux-firebase'
import React, { useEffect, useState, useCallback } from "react";
import {Table, Button, Icon,  Modal} from 'react-materialize';
import {SessionEventsConnected} from './SessionEvents';
import FTBreadcrumb from './Util/FTBreadcrumb';
import { useSelector } from "react-redux";

const CameraSessions = ({firestore}) => {
    
    const {cameraId} = useParams();
    const history = useHistory();
    const location = useLocation();
    const [sessions, setSessions] = useState([]);
    
    const [sessionsArchived, setSessionsArchived] = useState([]);
    const [selectedSession, setSelectedSession] = useState(null);


    const [cameraData, setCameraData] = useState(null);

    const clickedSession = useCallback(
        (session) => {
            setSelectedSession(session);
        },
        [sessions],
    )

    const closeEvents = useCallback(
        () => {
            setSelectedSession(null);
        },
        [],
    );

    useEffect(() => {
        firestore.collectionGroup("sessions").where("camId", "==", cameraId).get() // Get sessions
        .then((qSnap)=>{
            setSessions(qSnap.docs.map(d=>{
                const data = d.data();
                const startTime = new Date(data.state.firstEventTimestamp).toLocaleString();
                const endTime = new Date(data.state.latestEventTimestamp).toLocaleString();
                return {...d.data(), startTime, endTime, camSessionId: d.id} }
                ));
            });

        firestore.collectionGroup("sessions_archived").where("camId", "==", cameraId).get() // Get sessions
        .then((qSnap)=>{
            setSessionsArchived(qSnap.docs.map(d=>{
                const data = d.data();
                const startTime = new Date(data.state.firstEventTimestamp).toLocaleString();
                const endTime = new Date(data.state.latestEventTimestamp).toLocaleString();
                return {...d.data(), startTime, endTime, camSessionId: d.id, archived:true} }
                ));
            });

        firestore.doc(`cameras/${cameraId}`).get() // Get Camera data
        .then((qDocSnap)=>{
            setCameraData(qDocSnap.data());
        })
        }, []);

    const tenantPathElems = cameraData?.tenantPath?.split("/");
    const tenantId = tenantPathElems?.length && tenantPathElems[tenantPathElems.length - 1];
    
    const farmPathElems = cameraData?.farmPath?.split("/");
    const farmId = farmPathElems?.length && farmPathElems[farmPathElems.length - 1];

    const allSessions = [...sessions, ...sessionsArchived].sort((s1, s2)=>s2.state.firstEventTimestamp - s1.state.firstEventTimestamp);

    //allSessions.sort((s1, s2)=>s1.startTime - s2.startTime);
    
    const breadcrumbNamesAndLinks = location.pathname.search("farms") <= 0 ?
        [
            ["Dashboard", "/"],
            ["Cameras", "/cameras"],
            [cameraId + " (camera)", `/cameras/${cameraId}`],
            ["sessions", `#`],
            
        ] :
        [
            ["Dashboard", "/"],
            [`Farm Camera ${cameraData?.farm_derived?.displayName} - ${cameraId}`, `/tenants/${tenantId}/farms/${farmId}/cameras/${cameraId}`],
            ["Sessions", `#`],
        ];
    return(
        <>
        <FTBreadcrumb namesAndLinks={breadcrumbNamesAndLinks} />

        <Table>
            <thead>
                <tr>
                    <th></th>
                    <th>Session Id</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                    <th>Archived</th>
                    
                </tr>
            </thead>
            <tbody>

                {allSessions?.map((ses) =>
                    <tr key={ses.camSessionId}>
                        <td><Button onClick={()=>setSelectedSession(ses)}><Icon>arrow_forward</Icon></Button></td>
                        <td >{ses.camSessionId}</td>
                        <td >{ses.startTime}</td>
                        <td >{ses.endTime}</td>
                        <td> {ses.archived && "Archived"}</td>
                    </tr>
                )}
            </tbody>
        </Table>
        {selectedSession && farmId && tenantId &&
        <Modal options={{ onCloseEnd: closeEvents }} className="events-modal" open={!!selectedSession}>
            <SessionEventsConnected archived={selectedSession.archived} tenantId={tenantId} farmId={farmId} sessionId={selectedSession.camSessionId} />
        </Modal>}
        </>
    )
   
} 


export const CameraSessionsWithFirestore = withFirestore(CameraSessions);