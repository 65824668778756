
import {camStates, vars} from "../../vars";
import { createElement } from "react";

export const getSessionIsActive = (category)=> {
    return ["acitve_noConnection","active", "unknown", "alarmed", "incomplete_startMissing"].includes(category);
}

export const activeInactiveReducerFnc = (acc, cur)=>{
    //console.log("Cur: ", cur);
    const active = getSessionIsActive(cur?.state?.category);
    const arr = active ? acc.active : acc.inactive;
    const curWithActive = {...cur, active};
    arr.push(curWithActive);
    return acc;
}

export const sortSessionsByAlarmed = (s1, s2)=>{
    
    const s1Alarmed = s1?.state?.category == "alarmed";
    const s2Alarmed = s2?.state?.category == "alarmed";
    return s1Alarmed == s2Alarmed ? 0 : s1Alarmed < 0 ? -1 : 1;
}


const camStatesForColors = {
    red: [camStates.fault],
    blue: [camStates.assisting, camStates.runningPaused],
    yellow: [],
    grey: [camStates.off, camStates.unknown],
    //green: [standby, charging, running, pigletCount, runningEnded],
}

const sessionCats = vars.sessionCategories;
const sessionStatesForColors = {
    red: [sessionCats.ALARMED],
    blue: [],
    yellow: [sessionCats.INCOMPLETE_NO_END_NEWER_SESSION,
        sessionCats.INCOMPLETE_NO_END_NEWER_CAM_EVENTS,
        sessionCats.ABORTED,
    ],
    grey: [sessionCats.ACTIVE_NO_CONNECTION, sessionCats.UNKNOWN],
    
}

export const colors = {
    red:"red darken-2",
    blue: "blue lighten-1",
    yellow: "amber",
    grey: "grey lighten-1",
    green: "green lighten-2"
}

export const getSessionColor = ({cameraState, state, battery, active})=> {

    //console.log("Ses color cameraState: ", cameraState);
    if(active)
    {

        if(active && (camStatesForColors.red.includes(cameraState?.camState) ||
        sessionStatesForColors.red.includes(state?.category)))
        {
            return colors.red;
        }
        else if(camStatesForColors.blue.includes(cameraState?.camState) ||
            sessionStatesForColors.blue.includes(state?.category))
        {
            return colors.blue;
        }
        else if(camStatesForColors.yellow.includes(cameraState?.camState) ||
        sessionStatesForColors.yellow.includes(state?.category) ||
        ([sessionCats.ACTIVE, sessionCats.ACTIVE_NO_CONNECTION].includes(state?.category) && state?.battery <= 15))
        {
            return colors.yellow;
        }
        else if(camStatesForColors.grey.includes(cameraState?.camState) ||
        sessionStatesForColors.grey.includes(state?.category))
        {
            return colors.grey;
        }
    }
    else {
        if([sessionCats.INCOMPLETE_NO_END_NEWER_SESSION,
            sessionCats.INCOMPLETE_NO_END_NEWER_CAM_EVENTS,
            sessionCats.ABORTED
        ].includes(state?.category))
            {
                return colors.yellow;
            }
    }
    return "green lighten-2";
}

export const insertDividers = (arr, extractSortData, extractDividerData, createDivider, createElement) =>{
    const returnArr = [];


    //Sort elems if fnc is provided
    const sortedArr = extractSortData ? arr.sort((a,b)=>{
        const sortA = extractSortData(a);
        const sortB = extractSortData(b);
        return sortA < sortB ? 1 : sortA == sortB ? 0 : -1;
    }) : [...arr];

    //
    var lastDividerData = undefined;

    sortedArr.forEach((element, i) => {
        const dividerData = extractDividerData(element);
        if(dividerData != lastDividerData)
        {
            lastDividerData = dividerData;
            returnArr.push(createDivider(dividerData, i))
        }
        returnArr.push(createElement(element, i));
        
    });

    return returnArr;
}