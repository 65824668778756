import React, { useEffect, useState } from 'react'
import { Collection, CollectionItem, Pagination, Icon, Collapsible, CollapsibleItem, Checkbox, Row, Col, Button } from 'react-materialize';
import { useFirestoreConnect } from 'react-redux-firebase';
import { withFirestore } from 'react-redux-firebase'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { EventHeader, EventDetail } from './Util/eventVisualisation';

import FTBreadcrumb from './Util/FTBreadcrumb';
export const CameraEventsViewFromLocation = (props) => {

    const { tenantId, cameraId } = useParams();

    useEffect(() => {
        //console.log(`Geting events for cam ${cameraId}`)
        //props.firestore.get({collection:`cameras/${cameraId}/events`, orderBy: ["timestamp", "desc"], storeAs:"events"});
        props.firestore.get({ collection: `cameras`, doc: cameraId, storeAs: "camera" });
    }, []);

    const role = useSelector(state => state.global.userClaims.role);
    const camera = useSelector(state => state.firestore.data.camera);

    const breadcrumbNamesAndLinks = role == "master" ? tenantId ?
        [
            ["Dashboard", "/"],
            ["Owners", "/Tenants"],
            [camera?.tenant_derived.displayName, `/tenants/${tenantId}`],
            [cameraId + " (camera)", `/tenants/${tenantId}/cameras/${cameraId}`],
            ["events", "#"]
        ] :
        [
            ["Dashboard", "/"],
            [cameraId + " (camera)", `/cameras/${cameraId}`],
            ["events", "#"]
        ] :
        [
            ["Dashboard", "/"],
            [cameraId + " (camera)", `/tenants/${tenantId}/cameras/${cameraId}`],
            ["events", "#"]
        ]

    return (
        <div>
            <FTBreadcrumb namesAndLinks={breadcrumbNamesAndLinks} />
            <CameraEventsConnected cameraId={cameraId} />
        </div>
    );
}

const EventListView = ({ eventsSelector }) => {
    const eventsPrPage = 20;
    const [page, setPage] = useState(1);
    const ev1Index = (page - 1) * eventsPrPage;

    const [includeHeartbeats, doIncludeHeartbeats] = useState(true);

    const [sortByCamTime, setSortByCamTime] = useState(true);

    const allEvents = useSelector(state => eventsSelector(state)?.filter(ev => includeHeartbeats || !ev.commandString.startsWith("HEARTBEAT"))/*.sort((e1, e2)=>{
        return e1.timestamp < e2.timestamp ? 1 : e1.timestamp > e2.timestamp ? -1 : e1.timestamp_server < e2.timestamp_server ? 1 : e1.timestamp_server > e2.timestamp_server ? -1 : 0})*/);
    if (sortByCamTime && allEvents) {
        allEvents.sort((e1, e2) => e1.timestamp < e2.timestamp ? 1 : e1.timestamp > e2.timestamp ? -1 : 0);
    }
    const events = useSelector(state => allEvents?.slice(ev1Index, ev1Index + eventsPrPage) || null);

    //console.log(`Event count: ${allEvents?.length}`);
    //console.log("Events: ", allEvents)
    const evCount = allEvents?.length || 0;
    const pageCount = Math.ceil(evCount / eventsPrPage);
    const [timeNow, setTimeNow] = useState(Date.now());

    const selectPage = (nr) => {
        setPage(nr);
    }

    return (
        !events ? <h5 className={"center-align"}>Loading events</h5> :
            events?.length == 0 ? <h5 className={"center-align"}>No events</h5> :
                <div>

                    <div className="center-align">
                        <Pagination
                            activePage={page}
                            items={pageCount}
                            onSelect={selectPage}
                            leftBtn={<Icon>chevron_left</Icon>}
                            maxButtons={8}
                            rightBtn={<Icon>chevron_right</Icon>}
                        />

                    </div>
                    <div>
                        &emsp;
                        <div className="left">
                            Filter:&emsp;
                        <Checkbox id="filterHeartbeats" filledIn className="right" checked={includeHeartbeats} value={"Some Value"} onChange={() => doIncludeHeartbeats(!includeHeartbeats)} label="Heartbeats" />
                        </div>
                        <div className="right">

                            Sort by:&emsp;
                        <Checkbox id="useCamTime" filledIn className="right 2" checked={sortByCamTime} value={"Some Value 2"} onChange={() => setSortByCamTime(!sortByCamTime)} label="Cam Time" />
                        </div>
                    </div>
                    <Collapsible accordion={false} >
                        {events?.map((e, i) => {
                            const eventKeyValTuples = Object.entries(e);
                            return <CollapsibleItem headerClassName="less-height" key={ev1Index + i}
                                header={<EventHeader {...{ ...e, name: new Date(e.timestamp).toLocaleString("en-GB"), timeNow: timeNow }} />}>
                                <EventDetail keyValTupleArray={eventKeyValTuples} />
                            </CollapsibleItem>
                        })}
                    </Collapsible>
                    <div className="center-align">
                        <Pagination
                            activePage={page}
                            items={pageCount}
                            onSelect={selectPage}
                            leftBtn={<Icon>chevron_left</Icon>}
                            maxButtons={8}
                            rightBtn={<Icon>chevron_right</Icon>}
                        />
                    </div>
                </div>
    );
}
const CameraEventsView = (props) => {

    const cameraId = props.cameraId;
    //const [page, setPage] = useState(1);
    //const [timeNow, setTimeNow] = useState(Date.now());
    //const eventsPrPage = 20;
    const eventsFirestorePath = `cameras/${cameraId}/events`;
    const eventsStoredPath = eventsFirestorePath.split("/").join("-");

    const loadEventsCount = 100;
    const [startEv, setstartEv] = useState(null);
    const [lastEvsInEpocs, setFirstEvsInEpocs] = useState([]);
    const [endEv, setEndEv] = useState(null);

    useEffect(() => {
        
        const lastEvForQuery = lastEvsInEpocs.length > 0 ? lastEvsInEpocs[lastEvsInEpocs.length -1] : null; 
        const startAfter = lastEvForQuery ? {
            startAfter: [lastEvForQuery.timestamp_server, lastEvForQuery.timestamp]
        } : {};
        
        //console.log(`Geting events for cam ${cameraId} starting at: `, Object.keys(startAfterEndBefore).map(key=>`${key}: ${new Date(startAfterEndBefore[key][0]).toLocaleDateString("en-GB")} / ${new Date(startAfterEndBefore[key][1]).toLocaleDateString("en-GB")}`));
        const queryObj = { collection: eventsFirestorePath, orderBy: [["timestamp_server", "desc"], ["timestamp", "desc"]], storeAs: eventsStoredPath, limit:1000, ...startAfter};
        //console.log("Query Obj: ", queryObj);
        props.firestore.get(queryObj); //only ordering by timestamp right now
        props.firestore.get({ collection: `cameras`, doc: cameraId });
    }, [lastEvsInEpocs]);

    const selectEvents = state => state.firestore.ordered[eventsStoredPath]//?.sort((e1, e2)=>{return e1.timestamp > e2.timestamp ? 1 : e1.timestamp < e2.timestamp ? -1 : 0});

    //&console.log("Sel events: ", selectEvents);

    const camera = useSelector(state => state.firestore.ordered?.cameras && state.firestore.ordered?.cameras[cameraId]);

    //const pageCount = Math.ceil(camera?.eventCount / eventsPrPage);
    //const startEv = (page - 1) * eventsPrPage;
    //const events = useSelector(state=>state.firestore.ordered.events?.slice(startEv, startEv + eventsPrPage) || []);

    //console.log("eventss: ", events);

    // const selectPage = (nr) =>{
    //     console.log("Set page to: ", nr);
    //     setPage(nr);
    // }


    const lastEvent = useSelector(state=>{
       const  evs = selectEvents(state);
        return evs?.length > 0 ? evs[evs.length - 1] : null;
    })

    
    const firstEvent = useSelector(state=>{
        const  evs = selectEvents(state);
         return evs?.length > 0 ? evs[0] : null;
     })

    const shiftEpoc = (back)=>{
        setstartEv(back ? lastEvent : null);
        //setEndEv(!back ? firstEvent : null);
        if(back)
        {
            setFirstEvsInEpocs([...lastEvsInEpocs, lastEvent]);
            //setstartEv(lastEvent);
        }
        else if(lastEvsInEpocs.length > 0)
        {
            setFirstEvsInEpocs(lastEvsInEpocs.slice(0, lastEvsInEpocs.length - 1));
            //setstartEv(firstEvsInEpocs)   
        }
    }
    //console.log("Last EVent:",  lastEvent);
    
    //console.log("First EVent:",  new Date(firstEvent?.timestamp).toLocaleDateString("en-GB"));

    return (
        <div>

        <div className="inline">
                <h4 className="truncate inline">
                Events for camera {cameraId}
                </h4>
            </div>
            <div className="inline right">
            {`${new Date(firstEvent?.timestamp).toLocaleDateString("en-GB")} <-> ${new Date(lastEvent?.timestamp).toLocaleDateString("en-GB")}`}
            <div>
                <div className="right">
                    <Button onClick={()=>shiftEpoc(false)}><Icon>chevron_left</Icon></Button>
                    <Button onClick={()=>shiftEpoc(true)}><Icon>chevron_right</Icon></Button>
                </div>
            </div>
            </div>
        <EventListView eventsSelector={selectEvents} />
    </div>);
}


const SessionEventsView = (props) => {

}

export const CameraEventsConnected = withFirestore(CameraEventsView);

export const CameraEventsFromLocationConnected = withFirestore(CameraEventsViewFromLocation)

