import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react'
import { Collection, CollectionItem, Pagination, Icon, Collapsible, CollapsibleItem, Checkbox, Row, Col, Button } from 'react-materialize';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useFirebase } from 'react-redux-firebase'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { EventHeader, EventDetail, FarmEventHeader } from './Util/eventVisualisation';
import { vars } from '../vars';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const FarmEventListView = ({ farmEvents }) => {
    const eventsPrPage = 8;
    const [page, setPage] = useState(1);
    const ev1Index = (page - 1) * eventsPrPage;

    const farmEventsSorted = useMemo(() => {
        return [...farmEvents].sort((e1, e2) => e1.timestamp < e2.timestamp ? 1 : e1.timestamp > e2.timestamp ? -1 : 0);
    }, [farmEvents]);


    const events = useSelector(state => farmEventsSorted?.slice(ev1Index, ev1Index + eventsPrPage) || null);

    const evCount = farmEventsSorted?.length || 0;
    const pageCount = Math.ceil(evCount / eventsPrPage);

    const selectPage = (nr) => {
        setPage(nr);
    }

    useEffect(() => {
        setPage(1);
    }, [farmEvents])

    return (
        !events ? <h5 className={"center-align"}>Loading events</h5> :
            events?.length == 0 ? <h5 className={"center-align"}>No events</h5> :
                <div>

                    <div>
                    </div>
                    <Collapsible accordion={false} >
                        {events?.map((e, i) => {
                            const eventKeyValTuples = Object.entries(e);
                            return <CollapsibleItem headerclassname="less-height" key={ev1Index + i}
                                header={<FarmEventHeader {...e} />}>
                                <EventDetail keyValTupleArray={eventKeyValTuples} />
                            </CollapsibleItem>
                        })}
                    </Collapsible>
                    <div className="center-align">
                        <Pagination
                            activePage={page}
                            items={pageCount}
                            onSelect={selectPage}
                            leftBtn={<Icon>chevron_left</Icon>}
                            maxButtons={8}
                            rightBtn={<Icon>chevron_right</Icon>}
                        />
                    </div>
                </div>
    );
}

const CheckboxGroup = ({ options, selected, onChange, filterName }) => {
    //console.log("Options: ", options);
    return (
        <div>
            {filterName}
            <Row>

                {options.map((opt) => {
                    return (
                        <Col key={opt.id}>
                            <Checkbox className="truncate" id={"Multi-" + opt.id} filledIn={true} checked={selected.includes(opt.id)} label={opt.name} value={opt.id} onChange={onChange} />
                        </Col>
                    )
                })}
            </Row>
        </div>
    )
}

const getFaultName = (ev) => (ev.faultCode && vars.FAULT_TYPES[ev.faultCode] || ev.commandString).substr(0, 14);

export const FarmEventsView = ({ farmEvents, periodStart, farmPath }) => {

    const [typesFilter, setTypesFilter] = useState([]);
    const [camsFilter, setCamsFilter] = useState([]);

    const [internalPeriodStart, setInternalPeriodStart] = useState(new Date(periodStart));

    const firebase = useFirebase();
    
    //calculate a list of all event types.
    const eventTypes = useMemo(() => {
        return farmEvents.reduce((prev, cur) => {
            const faultName = getFaultName(cur);
            return prev.includes(faultName) ? prev : [...prev, faultName];
        }, [])
        .map((eT) => { return { id: eT, name: eT } });
    }, [farmEvents]);
    
    
    //calculate a list of all cameras.
    const camIds = useMemo(() => {
        return farmEvents.reduce((prev, cur) => {
            const camId = (cur.cameraId || "?").substr(0, 7);
            return prev.includes(camId) ? prev : [...prev, camId];
        }, [])
        
        .map((camId) => { return { id: camId, name: camId } });
    }, [farmEvents]);

    const onChangePeriodStart = useCallback((newPeriodStart)=>{
        setInternalPeriodStart(newPeriodStart);
        console.log(`New Period start: ${Date.parse(newPeriodStart)}`);

        firebase.firestore().doc(farmPath).update({farmEventsPeriodStart: Date.parse(newPeriodStart)})
    }, [setInternalPeriodStart]);

    const onChangeTypes = useCallback(
        (e) => {
            const nowChecked = e.currentTarget.checked;
            const id = e.currentTarget.value;
            setTypesFilter(nowChecked ? [id, ...typesFilter] : typesFilter.filter(f => f !== id));
        },
        [typesFilter],
    )

    const onChangeCams = useCallback(
        (e) => {
            const nowChecked = e.currentTarget.checked;
            const id = e.currentTarget.value;
            setCamsFilter(nowChecked ? [id, ...camsFilter] : camsFilter.filter(c => c !== id));
        },
        [camsFilter],
    )

    const filteredEvents = useMemo(() => {
        return farmEvents
            .filter(ev => camsFilter.length === 0 || camsFilter.includes(ev.cameraId))
            .filter(ev => typesFilter.length === 0 || typesFilter.includes(getFaultName(ev)));
    }, [farmEvents, typesFilter, camsFilter])

    return (
        <div>
            <div style={styles.headerContainer} className="inline">
                <h4 className="truncate inline">
                    Farm Events
                </h4>
                <div style={styles.spacer} />
                <div style={styles.filtersContainer}>
                    <div style={styles.singleFilterContainer}>
                        <CheckboxGroup filterName={"Cameras"} options={camIds} selected={camsFilter} onChange={onChangeCams} />
                    </div>  
                    <div style={styles.singleFilterContainer}>
                        <CheckboxGroup filterName={"Types"} options={eventTypes} selected={typesFilter} onChange={onChangeTypes} />
                    </div>
                    <div style={styles.singleFilterContainer}>
                        <div>Since:</div>
                        {internalPeriodStart && <DatePicker  dateFormat="dd/MM/yyyy" style={{zIndex: 100}} selected={internalPeriodStart} onSelect={onChangePeriodStart} />}
                    </div>
                </div>
            </div>
            <FarmEventListView farmEvents={filteredEvents} />
        </div>);
}

const styles = {
    headerContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"

    },
    filtersContainer: {
        display: "flex",
        maxWidth: "75%",
        flexDirection: "row",
        justifyContent: "flex-end",
    },
    singleFilterContainer: {
        display: "flex",
        maxWidth: "75%",
        //flex:1,
        backgroundColor: "#DDD",
        flexDirection: "row",
        //padding: ".3em",
        marginLeft: "1em",

    },
    spacer: {
        width: "5%",
    },
    filterElem: {
        width: "40%",
        maxWidth: "40%",
    }
}