import React from 'react';
import firebase, { firestore } from 'firebase/app';
import {makeid} from './../utils'
import M from "materialize-css";
import {  toast } from 'react-toastify';

import { useHistory } from 'react-router-dom';
var messaging;
var clientId;

var lastToken;

const getToken = ()=>{
    messaging.getToken().then((currentToken) => {
        if (currentToken) {
            lastToken = currentToken;
            console.log("Valid token received")
            sendTokenToServer(currentToken);
        } else {
            // Show permission request.
            console.log('No Instance ID token available. Request permission to generate one.');
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
    });
}

// Send the Instance ID token your application server, so that it can:
// - send messages back to this app
// - subscribe/unsubscribe the token from topics
function sendTokenToServer(currentToken) {
    
    console.log(`Sending token ${currentToken} for clientId: ${clientId} to server...`);

    firebase.functions().httpsCallable("setToken")({clientId: clientId, token: currentToken});
}

const ToastWithLink = ({heading, body, link }) => {
    const history = useHistory();

    return(
        <div onClick={()=>history.push(link)}>
            <p><b>{heading}</b></p>
            <p>{body}</p>
        </div>
    )
}
export const setupMessaging = async (pushHistoryFnc)=>{
    
    console.log("Setting up messaging")
    const clientIdKey = "farrowAppClientId";
    clientId = window.localStorage.getItem(clientIdKey);

    if(!clientId)
    {
        clientId = makeid(8)
        window.localStorage.setItem(clientIdKey, clientId);
    }
    

    messaging = firebase.messaging();
    
    getToken();
    
    messaging.onTokenRefresh(getToken);
    // Handle incoming messages. Called when:
    // - a message is received while the app has focus
    // - the user clicks on an app notification created by a service worker
    //   `messaging.setBackgroundMessageHandler` handler.

    const showToast = (payload) => {
        //console.log('Message received. ', payload);
        const link = payload.data?.link;
        const options = {};
        if(link)
        options.onClick = ()=>pushHistoryFnc(link);
        toast(<ToastWithLink {...payload.data}/>);
    }
    messaging.onMessage(showToast, {autoClose:10000});
    //showToast({data:{heading:"Hejsa heading", body:"Noget body text", link:"/tenants/uid_test_tenant1/farms/ccf7cae6-d156-4cf6-b084-dfc19ed1d16e?focus=d053"}})
}
//toast(<div><h3>Hello</h3><p>text</p><button onClick={console.log("Clicked")}>Knap</button></div>);

/*toast("hello", {
    onClick:()=>console.log("CLicked"),
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });
    */

export const getCurrentClientIdAndToken = () => {
    return{clientId, token:lastToken};
}