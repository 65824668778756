import React, { useState, useEffect } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { Link, useParams, useLocation } from 'react-router-dom'
import SignedInLinks from './signedInLinks'
import SignedOutLinks from './signedOutLinks'
import { NavLink, useHistory } from 'react-router-dom'
import { useFirestore, useFirebase, firebaseReducer, useFirebaseConnect, useFirestoreConnect } from 'react-redux-firebase';
import { Navbar, Button, NavItem, Dropdown, Icon, Divider, CollapsibleItem, Col, Row } from 'react-materialize';
import { setShowingUserNav } from "../../modules/store/actions/globalActions";
import {getCurrentClientIdAndToken} from "../../modules/firebase/cloudMessaging";
import {MultiSelectModal} from "./../Util/MultiSelectModal"

const logoSource = "https://farrowtech.dk/wp-content/uploads/2020/02/FarrowTech-logo_392x82-1.png"
const BrandElement =<div style={{height:"100%"}}> <a style={{ height: "100%" }} className="brand-logo" href="#"><img style={{ height: "100%" }} src={logoSource} /></a></div>;

export const TenantMasterNavbar = (props) => {

    const firestore = useFirestore();
    const history = useHistory();
    
    const [preferencesOpen, setPreferencesOpen] = useState(false);
    
    const claims = useSelector(({ global}) => global?.userClaims);
    //console.log("Claims: ", claims);
    
    useFirestoreConnect(() => [
        {   
            collection: 'users',
            doc: claims.user_id,
            subcollections: [
                {
                    collection: 'preferences',
                    doc: "0",
                }
            ],
            storeAs: "userPrefs"
        }
    ])

    const notificationPrefs = useSelector(({firestore: {data}}) => data?.userPrefs?.notifications);



    const notificationPRefsEntries = notificationPrefs && Object.entries(notificationPrefs) || []
    const firebase = useFirebase();
    const signOut = () => {
        console.log("Signing out")
        firebase.auth().signOut().then(()=>history.push("/"));
    }

    const submitPreferences = (prefs)=>{
        const submitObj = {notifications:{}};
        notificationPRefsEntries.forEach(kvArr=>submitObj.notifications[kvArr[0]] = prefs.includes(kvArr[0]));

        const deregisterTokenAndClientId = firebase.functions().httpsCallable("deregisterTokenAndClientId");
        console.log("Submitting prefs: ", prefs);
        return firestore.doc(`users/${claims.user_id}/preferences/0`).update(submitObj)
        .then(()=>{
            if(prefs.length === 0)
            {
                console.log("Length == 0")
                return deregisterTokenAndClientId(getCurrentClientIdAndToken());
            }
            else{
                Promise.resolve();
            }
        })
        .then(()=>setPreferencesOpen(false));
    }
    const accountName = claims?.email || claims?.name
    return (
        <div>

        <Navbar
            className="navbar"
            alignLinks="right"
            brand={<a style={{ height: "100%" }} className="brand-logo" href="#"><img style={{ height: "100%" }} src={logoSource} /></a>}
            id="mobile-nav"
            menuIcon={<Icon>menu</Icon>}
            options={{
                draggable: true,
                edge: 'left',
                inDuration: 250,
                onCloseEnd: null,
                onCloseStart: null,
                onOpenEnd: null,
                onOpenStart: null,
                outDuration: 200,
                preventScrolling: true
            }}
            >
            {accountName && <NavItem onClick={()=>setPreferencesOpen(!preferencesOpen)}><div style={{display:"flex"}}>
                <span style={{paddingRight:"1em"}}>{accountName}</span>
                <Icon>account_box </Icon>
                </div>
                </NavItem>}
           
            {<NavItem className="nav-item" onClick={signOut} >Logout</NavItem>}
        </Navbar>
        <MultiSelectModal  options={notificationPRefsEntries.map(kvArr=>{return {id:kvArr[0], name:kvArr[0]}})} preselectedIds={notificationPRefsEntries.filter(kvArr=>kvArr[1]).map(kvArr=>kvArr[0])} header={`Active notifications`} open={preferencesOpen} onSubmit={submitPreferences} close={()=>setPreferencesOpen(false)} />
            </div>
    )
}




export const UserNavbar = ({ farmPaths_derived }) => {

    
    //console.log("Farm paths derived: ", farmPaths_derived)
    const firebase = useFirebase();

    const dispatch = useDispatch();

    const history = useHistory();
    
    const firestore = useFirestore();

    const [farmData, setFarmData] = useState({});

    const [preferencesOpen, setPreferencesOpen] = useState(false);
    
    //const [farmDataOrdered, setFarmDataOrdered] = useState([]);

    useEffect(() => {
        if(!farmPaths_derived)
        return;
        console.log("New farmPaths: ", farmPaths_derived)
        const farms = [];
        Promise.all(farmPaths_derived.map(fP=> firestore.doc(fP).get()
                .then(qDocSnap=>farms.push(qDocSnap.data()))))
                
        .then(()=>{setFarmData(farms)})
        .catch(e=>console.log("Caught error fetching farms: ", e));

       }, [farmPaths_derived]);

       const submitPreferences = (prefs)=>{
        console.log("PRefs: ", prefs);
        const submitObj = {notifications:{}};
        notificationPRefsEntries.forEach(kvArr=>submitObj.notifications[kvArr[0]] = prefs.includes(kvArr[0]));

        return firestore.doc(`users/${claims.user_id}/preferences/0`).update(submitObj)
        .then(()=>setPreferencesOpen(false));
    }
    
    const farmPaths_derivedOrFake = farmPaths_derived?.length > 0 || ["poul"];

    const { tenantId, farmId } = useParams();

    const role = useSelector(state => state.global.userClaims.role);

    const claims = useSelector(({ global}) => global?.userClaims);
    const accountName = claims?.email || claims?.name

        
    useFirestoreConnect(() => [
        {   
            collection: 'users',
            doc: claims.user_id,
            subcollections: [
                {
                    collection: 'preferences',
                    doc: "0",
                }
            ],
            storeAs: "userPrefs"
        }
    ])

    const notificationPrefs = useSelector(({firestore: {data}}) => data?.userPrefs?.notifications);

    const notificationPRefsEntries = notificationPrefs && Object.entries(notificationPrefs) || []

    //console.log("farms: ", farmData);
    useEffect(() => {
        console.log("Should dispatch showing User Nav")
        dispatch(setShowingUserNav(true));
        return () => {
            dispatch(setShowingUserNav(false));
        }
    }, [])
    /*
    useFirestoreConnect(() => {
        const subCollectionObj = role === "master" ? { collection: "farms" } :
            { collection: "farms", where: ["path", "in", farmPaths_derivedOrFake] };


        return (
            {
                collection: "tenants",
                doc: tenantId,
                subcollections: [subCollectionObj],
                storeAs: "availableFarms"
            })
    }
    )
    */
    const signOut = () => {
        console.log("Signing out")
        firebase.auth().signOut().then(()=>history.push("/"));
    }

    //const farmData = useSelector(({ firestore: { data } }) => data.availableFarms);
    const farmDataOrdered = useSelector(({ firestore: { ordered } }) => ordered.availableFarms);
    const location = useLocation();

    //console.log("Link path: ", location.pathname.split("/").slice(0, -1).join("/"));
    return (
        <div>
        <Navbar
            className="navbar"
            alignLinks="right"
            brand={BrandElement}
            id="mobile-nav"
            menuIcon={<Icon>menu</Icon>}
            options={{
                draggable: true,
                edge: 'left',
                inDuration: 250,
                onCloseEnd: null,
                onCloseStart: null,
                onOpenEnd: null,
                onOpenStart: null,
                outDuration: 200,
                preventScrolling: true
            }}
        >
             {accountName && <NavItem onClick={()=>setPreferencesOpen(!preferencesOpen)}><div style={{display:"flex"}}>
                <span style={{paddingRight:"1em"}}>{accountName}</span>
                <Icon>account_box </Icon>
                </div>
                </NavItem>}
            {!farmData.length > 0 && <div>No farms</div>}
            {role === "user" ? <NavItem className="nav-item" onClick={signOut} >Logout</NavItem> :
                <NavLink className="nav-item" to={location.pathname.split("/").slice(0, -1).join("/")}>Back to Tenant View</NavLink>
            }
            {farmData.length > 0  && <Dropdown
                className="nav-item"
                style={{minWidth:"20em"}}
                id="Dropdown_6"
                options={{
                    alignment: 'left',
                    autoTrigger: true,
                    closeOnClick: true,
                    constrainWidth: true,
                    container: null,
                    coverTrigger: true,
                    hover: false,
                    inDuration: 150,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 250
                }}
                trigger={<a className="nav-item" >Farm: {farmData?.hasOwnProperty(farmId) && farmData[farmId]?.displayName}<Icon right>arrow_drop_down</Icon></a>}
                >
                {farmData?.map((fD, i) => <Link key={i} to={`/${fD.path}/sessions`}>{fD.displayName}</Link>)}

            </Dropdown >}
            
        </Navbar>
        <MultiSelectModal options={notificationPRefsEntries.map(kvArr=>{return {id:kvArr[0], name:kvArr[0]}})} preselectedIds={notificationPRefsEntries.filter(kvArr=>kvArr[1]).map(kvArr=>kvArr[0])} header={`Active notifications`} open={preferencesOpen} onSubmit={submitPreferences} close={()=>setPreferencesOpen(false)} />
    </div>
            );
}