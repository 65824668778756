import React from 'react'
import { useFirestore, isLoaded, isEmpty, useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'react-redux';

import { useParams, useHistory, NavLink, useLocation, Link } from 'react-router-dom';
import {Button, Icon, Breadcrumb} from 'react-materialize';
import FTBreadcrumb from './Util/FTBreadcrumb';

export default function Users() {

    const {tenantId, userId} = useParams();
    const userStorePath = `user${userId}`;
    
    const firestore = useFirestoreConnect (
        {
            collection: 'users',
            doc: userId,
            storeAs:"selectedUser"
        }
    );

    const userData = useSelector(({firestore: {data}})=> data.selectedUser);

    console.log("userData: ", userData)
    
    return (
        <div>
            
        </div>
    )
}
