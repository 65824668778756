import React, { useState, useEffect } from 'react'
import { Button, Icon, Breadcrumb, Row, Col, Card, Divider } from 'react-materialize';
import { NavLink, useHistory } from 'react-router-dom';
import { useSelector} from 'react-redux';
import { useFirebaseConnect, useFirestoreConnect, getFirebase } from 'react-redux-firebase'
import LinkCollection from './Util/LinkCollection';
import { UserLinkCollection, FarmLinkCollection } from './DynamicLinkCollections';
import { TenantDetailView } from "./TenantDetail";
import { KeyValGrid } from './Util/KeyValGrid';
import firebase, { firestore } from 'firebase/app';
import M from "materialize-css";
import FTBreadcrumb from './Util/FTBreadcrumb';

import FarmDetail from './FarmDetail';

const NavCard = (props) => {
    return (
        <NavLink to={props.linkTarget}>
            <Col
                m={6}
                s={12}
            >
                <Card
                    className="blue-grey darken-1 hoverable"
                    textClassName="white-text"
                    title={props.title}
                >

                    {props.subtitle}
                </Card>

            </Col>
        </NavLink>
    );
}

export const Dashboard = () => {

    const userClaims = useSelector(state => state.global?.userClaims);
    //console.log("User Claims: ", userClaims);
    const DashboardComponent = !userClaims ? null : userClaims.role == "master" ? Dashboard_Master : userClaims.role == "tenant" ? Dashboard_Tenant : Dashboard_User_Container;
    return (<DashboardComponent user_id={userClaims.user_id} role={userClaims.role} />);
}

export const Dashboard_Master = () => {

    const clickUpdateSessionStates = () => {
        const interpret = getFirebase().functions().httpsCallable("interpretActiveSessions");
        interpret()
            .then((res) => {
                console.log("Result: ", res);
                M.toast({ html: "Sent update sessions request" })
            })
    }

    const clickUpdateCamStates = () => {
        const interpret = getFirebase().functions().httpsCallable("interpretCameraStates");
        interpret()
            .then((res) => {
                console.log("Result: ", res);
                M.toast({ html: "Sent update camera states request" })
            })
    }

    const clickReinterpretAll = () => {
        const interpret = getFirebase().functions().httpsCallable("reinterpretAllSessions2");
        interpret()
            .then((res) => {
                console.log("Result: ", res);
                M.toast({ html: "Requested reinterpretation of all sessions!" })
            })
    }

    return (
        <div>
            <Breadcrumb
                className="teal"
                cols={12}
            >
                <a href="/">
                    Dashboard
                </a>
            </Breadcrumb>
            <div className="container">
                <h1>Dashboard</h1>

                <Row>
                    {/* <NavCard linkTarget="/users" title="Users" subtitle="Get a list of users"/> */}
                    <NavCard linkTarget="/tenants" title="Owners" subtitle="Get a list of owners" />
                    <NavCard linkTarget="/cameras" title="Cameras" subtitle="Get a list of cameras" />
                    
                    <NavCard linkTarget="/apiKeys" title="API Keys" subtitle="Manage API Access" />

                </Row>
                <Row style={{display:"none"}}>
                   <Button onClick={clickUpdateSessionStates}>Perform perdiodic Active Session Update<Icon>update</Icon></Button>
                </Row>
                
                <Row style={{display:"none"}}>
                    <Button onClick={clickUpdateCamStates}>Perform perdiodic Camera State Update<Icon>update</Icon></Button>
               </Row>
               
               <Row style={{display:"none"}}>
                    <Button onClick={clickReinterpretAll}>Reinterpret  -ALL-  sessions<Icon>update</Icon></Button>
               </Row>
            </div>
        </div>
    )
}

export const Dashboard_Tenant = (props) => {

    return (<TenantDetailView tenantPath={`tenants/${props.user_id}`} {...props} />);

    const [addUserOpen, setAddUserOpen] = useState(false);
    const [addFarmOpen, setAddFarmOpen] = useState(false);

    useFirestoreConnect(() => [
        {
            collection: 'tenants',
            doc: props.user_id,

        }, // or `todos/${props.todoId}`
        {
            collection: 'tenants',
            doc: props.user_id,
            subcollections: [
                {
                    collection: 'farms',
                }
            ],
            storeAs: "tenantFarms"
        }
    ])

    console.log("Dashboard tenant props: ", props);
    const tenant = useSelector(({ firestore: { data } }) => data?.tenants && data.tenants[props.user_id]);
    const farms = useSelector(({ firestore: { ordered } }) => ordered.tenantFarms || [])
    const users_derived = useSelector(({ firestore: { data } }) => data?.tenants && data.tenants[props.user_id]?.users_derived || []);

    const farmNamesAndLinks = farms.map(f => [f.displayName, `/tenants/${props.user_id}/farms/${f.id}`]);

    const userNamesAndLinks = users_derived?.filter(uD => uD.id != props.user_id).map(tU => [tU.displayName, `/tenants/${props.user_id}/${tU.path}`]);

    //console.log("Tenant obj; ", tenant);
    const detailDataArray = [
        ["Name", tenant?.displayName_derived],
        ["Active", tenant?.active ? "Yes" : "No"],
        // ["Created at", new Date(tenant.createdAt.seconds * 1000).toTimeString()],
        //["Owner Id", tenant.ownerId],
    ];

    return (
        <div>
            <Breadcrumb
                className="teal"
                cols={12}
            >
                <a href="/">
                    Dashboard
                </a>
            </Breadcrumb>
            <div className="container">

                <h4>Tenant </h4>
                <KeyValGrid propAndValuePairArray={detailDataArray} />
                <Row>
                    <Col m={6} s={12}>
                        <h4>Farms</h4>
                        <FarmLinkCollection nameAndLinkTuples={farmNamesAndLinks} tenantId={props.user_id} canAdd={tenant?.active} />
                    </Col>
                    <Col m={6} s={12}>
                        <h4>Users</h4>
                        <UserLinkCollection nameAndLinkTuples={userNamesAndLinks} canAdd={tenant?.active} />
                    </Col>
                </Row>
            </div>
        </div>
    )
}
//Getting user dashboard data is a two-step process because we have to get the tenantId and farmPaths
//before we can formulate the query for specific farms
export const Dashboard_User_Container = ({ user_id, role }) => {
    const history = useHistory();

    //console.log("Dashboard user container for user: ", user_id)
    useFirestoreConnect(() => [
        {
            collection: 'users',
            doc: user_id,
        }
    ]);

    const user = useSelector(({ firestore: { data } }) => data.users && data.users[user_id]);
        useEffect(() => {
            const farmPaths = user?.farmPaths_derived;
            if(user)
            {
                if(user?.farmPaths_derived?.length > 0)
                {
                    history.push(`${user?.farmPaths_derived[0]}/sessions`);
                }
                else
                {
                    history.push(`${user?.tenantPath}/farms/nofarm/sessions`)                    
                }
            }
            console.log("Effect")
        }, [user]);

    console.log("User: ", user);

    console.log("User_id: ", user_id);
    return <div>Redirecting to farm</div>
    //return (user ? <Dashboard_User {...{ user_id, role, user }} /> : null)
}

export const Dashboard_User = ({ user_id, role, user }) => {


    const tenantId = user?.tenantPath?.substring(user.tenantPath.lastIndexOf("/") + 1);
    /*
    useFirestoreConnect(() => user.farmPaths_derived.map((fP, i)=>{
        const [tenants, tenantId, farms, farmId] = fP.split("/");

        console.log(`Tenant: ${tenantId} and farm: ${farmId}`)
        return (
        
            {
                collection:"tenants",
                doc: tenantId,
                subCollections: [
                    {
                        collection:"farms",
                        doc: farmId
                    }
                ],
                storeAs: "farm" + i,
        });
    }))
*/
    useFirestoreConnect(() => [
        {
            collection: "tenants",
            doc: tenantId,
            subcollections: [
                {
                    collection: "farms",
                    //doc: "lkjlkj",//user.farmPaths_derived[0].split("/").pop(),
                    where: (["userPaths", "array-contains", "users/" + user_id])
                }
            ],
            storeAs: "userFarms"
        },
        {
            collection: "cameras",
            where: (["farmPath", "in", user?.farmPaths_derived?.length > 0 ? user.farmPaths_derived : [""]])

        }
    ]);
    const farms = useSelector(({ firestore: { ordered } }) => ordered.userFarms || [])
    const cams = useSelector(({ firestore: { ordered } }) => ordered.cameras);

    console.log("Farms: ", farms);
    console.log("cams: ", cams);

    const farmNamesAndLinks = farms.map(f => [f.displayName, `/tenants/${tenantId}/farms/${f.id}`]);
    const cameraNamesAndLinks = cams?.map((cam, i) => [cam.id, `/tenants/${tenantId}/cameras/${cam.id}`]) || [];

    return (
        <div>
            <FTBreadcrumb namesAndLinks={[["Dashboard", "#"]]} />
            <div className="container">
                <h4>{`Welcome ${user.displayName}`}</h4>
                <Divider />
                <h4>Farms</h4>
                <Col m={6} s={12}>
                    <FarmLinkCollection nameAndLinkTuples={farmNamesAndLinks} tenantId={tenantId} canAdd={false} />
                </Col>
                <Col m={6} s={12}>
                    <h4>Cameras</h4>
                    <LinkCollection nameAndLinkPairArray={cameraNamesAndLinks} />
                </Col>
            </div>
        </div>
    )
}
