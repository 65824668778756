import React, { useState, Component, useEffect, useCallback } from 'react'
import { useFirebaseConnect, useFirestoreConnect, getFirebase, useFirebase } from 'react-redux-firebase'
import firebase, { firestore } from 'firebase/app';
import { useParams, useHistory, NavLink, useLocation, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Icon, Modal, Breadcrumb, Select, Row, Col, Collection, CollectionItem, Collapsible, CollapsibleItem, Divider, Checkbox } from 'react-materialize';
import { KeyValGrid } from './Util/KeyValGrid';
import LinkCollection from './Util/LinkCollection';
import FTBreadcrumb from './Util/FTBreadcrumb';

import { useInterval } from './Util/useInterval';
import { createSelector } from 'reselect'
import { ModalForm, ConfirmModal_Delete, EndSessionModal } from './Util/ModalForms';
import { UserLinkCollection } from './DynamicLinkCollections';
import { MultiSelectModal } from "./Util/MultiSelectModal"
import { SessionEventsConnected } from "./SessionEvents";
import { CameraEventsConnected } from "./CameraEvents";
import { FarmEventsView } from "./FarmEvents";
import M from "materialize-css";
import { getFirestore } from 'redux-firestore';
import { vars } from "./../vars";
import * as manualEvents from "./../modules/events";
import { sortSessionsByAlarmed, activeInactiveReducerFnc, getSessionColor, colors } from "./Util/sessionUtils"
import { getCamStateColor, getColorForReactionTime } from "./Util/inferStyles";
import { setShowingDetailedMaster } from "./../modules/store/actions/globalActions";
import { SessionsViewConnected } from "./Sessions";

import qs from 'qs';

const catergoriesWithEndBtn = [vars.sessionCategories.ACTIVE,
vars.sessionCategories.ABORTED,
vars.sessionCategories.ACTIVE_NO_CONNECTION,
vars.sessionCategories.INCOMPLETE_NO_END_NEWER_SESSION,
vars.sessionCategories.INCOMPLETE_NO_END_NEWER_CAM_EVENTS,
vars.sessionCategories.ALARMED]
const SessionHeader = (props) => {
    //console.log("Session props: ", props);
    const minsSinceBirth = !props.state?.latestBirthTimestamp ? "?" : Math.floor((Date.now() - props.state?.latestBirthTimestamp) / 60_000);
    const minnsSinceEvent = Math.floor((Date.now() - props.state?.latestEventTimestamp) / 60_000);
    //console.log("Mins since: ", minsSinceBirth);
    return (

        <Row style={{ width: "100%", height: "1em" }} className="centser-align">
            <Col m={3}>Pen: {props.penNumber}</Col>
            <Col m={3}>Battery: {props.state?.battery}%</Col>
            {props.active ?
                <Col m={3}>Position: {props?.state?.numberInLitter}</Col> :
                <Col m={3}>{props?.state?.pigletsConfirmedAlive + props?.state?.pigletsConfirmedDead} / {props?.state?.numberInLitter} {props.reactionMean != -1 && " / ~" + props.reactionMean}</Col>
            }
            <Col m={3}>{props.active ? minsSinceBirth + " mins" : minnsSinceEvent + " mins ago"}</Col>
        </Row>
    )
}
const SectionHeader = ({ heading, empty, active }) => {
    return (
        <div className="section-header">
            <h5 className="center-align">{heading}</h5>
            {empty ? <div className="center-align">none</div> : <Row style={{ width: "100%", height: "1em" }} >
                <Col s={3}>Pen</Col>
                <Col s={3}>Battery</Col>
                <Col s={3}>{active ? "Position" : "Conf. / Det. / React."}</Col>
                <Col s={3}>{active ? "Since birth" : "Last Activity"}</Col>
                {/* <Col s={1}>State</Col> */}
            </Row>}
        </div>)
}
const SessionDetail = (props) => {


    const clickEnd = async () => {

        props.showEndModal(props);
        console.log("Props: ", props);
    }

    const state = props.state;

    const sessionEventsObject = {
        type: "session",
        sessionId: props.camSessionId
    }
    const camEventsObject = {
        type: "cam",
        cameraId: props.camId
    }


    return (
        <div>
            <Row className="dense-data">
                <Col m={4}>
                    <Row className="dense-data"><Col m={4}>Pen</Col><Col m={6}>{props.penNumber || "?"}</Col></Row>
                    <Row className="dense-data"><Col m={4}>Sow</Col><Col m={6}>{props.sowNumber || "?"}</Col></Row>
                    <Row className="dense-data"><Col m={4}>Category</Col><Col m={6}>{state?.category}</Col></Row>
                </Col>
                <Col m={4}>
                    <Row className="dense-data"><Col m={7}>{(props.active ? "Assisted" : "Confirmed")} alive</Col><Col m={5}>{state?.pigletsConfirmedAlive}</Col></Row>
                    <Row className="dense-data"><Col m={7}>{(props.active ? "Assisted" : "Confirmed")}  Dead</Col><Col m={5}>{state?.pigletsConfirmedDead}</Col></Row>
                </Col>
                <Col m={4}>
                    <Row className="dense-data"><Col m={5}>Session</Col><Col m={7}>{props.camSessionId}</Col></Row>
                    <Row className="dense-data">
                        <Col m={5}>Cam</Col>
                        <Col className="truncate" m={7}><NavLink to={`/tenants/${props.tenantId}/cameras/${props.camId}`}>{props.camId}</NavLink></Col>
                    </Row>
                    <Row className="dense-data"><Col m={5}>Reactions</Col><Col m={7}>{props.reactionMean >= 0 ? "~" + props.reactionMean + " mins." : "none"}</Col></Row>
                </Col>
            </Row>
            {props.role == "master" &&
                <div>
                    <Divider />
                    <Row className="dense-data">
                        <Col m={3}>Last Birth</Col><Col m={3}>{state?.latestBirthTimestamp && new Date(state?.latestBirthTimestamp).toLocaleString("en-GB")}</Col>
                        <Col m={3}>Interpreted at</Col><Col m={3}>{state?.updatedAt && new Date(state?.updatedAt.seconds * 1000).toLocaleString("en-GB")}</Col>
                    </Row>
                    <Row className="dense-data">
                        <Col m={3}>First Cam EVent</Col><Col m={3}>{state?.firstEventTimestamp && new Date(state?.firstEventTimestamp).toLocaleString("en-GB")}</Col>
                        <Col m={3}>Last Cam Event</Col><Col m={3}>{state?.latestCamEventTimestamp && new Date(state?.latestCamEventTimestamp).toLocaleString("en-GB")}</Col>
                        {/* <Col m={3} onClick={()=>props.showEvents(camEventsObject)} className="right text-btn" >Cam Events<Icon className="align-bottom">open_in_browser</Icon></Col>
                    <Col m={3} onClick={()=>props.showEvents(sessionEventsObject)} className="right text-btn" >Session Events<Icon>open_in_browser</Icon></Col> */}
                    </Row>

                    <Divider />
                    {catergoriesWithEndBtn.includes(state?.category) &&
                        <Button small className="btn-spacing-left" onClick={clickEnd}>End</Button>}

                    <Button small className="btn-spacing-left" onClick={() => props.showEvents(camEventsObject)}>Cam events</Button>
                    <Button small className="btn-spacing-left" onClick={() => props.showEvents(sessionEventsObject)} >Session events</Button>
                    <Button small className="btn-spacing-left right" onClick={() => props.requestInterpretation(props.camSessionId)} >Reinterpret</Button>
                    {/* <NavLink to={`${location.pathname}/cameras/${props.camId}/sessions/${props.camSessionId}/events`}>
                            See session events
                        </NavLink>
                        <NavLink style={{marginLeft:"1em"}} to={`${location.pathname}/cameras/${props.camId}/events`}>
                            See Camera events
                        </NavLink> */}

                    {/*state.category == vars.sessionCategories.INCOMPLETE_NO_END_NEWER_SESSION*/}

                </div>}

        </div>
    )
}

export default function FarmDetail(props) {
    const history = useHistory();

    const { tenantId, farmId } = useParams();

    const farmPath = `tenants/${tenantId}/farms/${farmId}`;

    //console.log(`Farm path: ${farmPath}`);
    const [endModalOpenSes, setEndModalOpenSes] = useState(null);

    const [deleteModalOpen, setdeleteModalOpen] = useState(false);

    const [timeNow, setTimeNow] = useState(Date.now());

    const [showingSessionEvents, setShowingSessionEvents] = useState(null);

    const [showingFarmEvents, setShowingFarmEvents] = useState(false);

    const clickedShowFaults = useCallback(
        () => {
            setShowingFarmEvents(true);
        },
        [setShowingFarmEvents],
    );

    const detailedMasterViewSelected = useSelector(state => state.global.showingDetailedMaster);

    const [showAll, setShowAll] = useState(false);

    const [farmEventsPeriodStart, setFarmEventsPeriodStart] = useState(0);
    const location = useLocation();
    const firebase = useFirebase();

    //Stuff related to greying out screen to focus certain areas
    const [focusingId, setFocusingId] = useState(null);
    const [fadingFocusSCreen, setFadingFocusSCreen] = useState(null);


    const mSecsPrHour = 1000 * 60 * 60;
    const hoursAgo = 72;
    const timestampSomeHoursAgo = Math.floor(Date.now() / mSecsPrHour - hoursAgo) * mSecsPrHour;

    useEffect(() => {
        const query = qs.parse(location.search, {
            ignoreQueryPrefix: true
        });
        //console.log("Query: ", query);
        //console.log("Location: ", location);
        if (query.focus) {
            setFocusingId(query.focus);
            setTimeout(() => setFocusingId(null), 4500);

            setTimeout(() => setFadingFocusSCreen("in"), 500);
            setTimeout(() => setFadingFocusSCreen("out"), 2500);
        }
    }, [location])

    useInterval(() => {
        setTimeNow(Date.now())
    }
        , 60_000);


    useFirestoreConnect(() => [

        {
            collection: 'tenants',
            doc: tenantId,
            subcollections: [
                {
                    collection: 'farms',
                    doc: farmId
                }
            ],
            storeAs: "tenantFarm",
        },
        {
            collection: 'tenants',
            doc: tenantId,
            storeAs: "farmTenant"
        },

        {
            collection: 'tenants',
            doc: tenantId,
            subcollections: [
                {
                    collection: 'farms',
                    doc: farmId,
                    subcollections: [
                        {
                            collection: 'sessions',
                            where: ["state.firstEventTimestamp", ">", showAll ? 0 : timestampSomeHoursAgo],
                            orderBy: ['state.firstEventTimestamp', "desc"],

                        },
                    ]
                }
            ],
            storeAs: ["farmSessions"],
        },
        {
            collection: 'tenants',
            doc: tenantId,
            subcollections: [
                {
                    collection: 'farms',
                    doc: farmId,
                    subcollections: [
                        {
                            collection: "events_farm_special",
                            where: ["timestamp", ">=", farmEventsPeriodStart],
                            orderBy: ["timestamp", "desc"],
                        },
                    ]
                }
            ],
            storeAs: ["farmEvents"],
        },
        {
            collection: 'cameras',
            where: [['farmPath', '==', farmPath]],
            storeAs: "farmCameras",
        },

    ])

    const farm = useSelector(({ firestore: { data } }) => data.tenantFarm)

    const tenant = useSelector(({ firestore: { data } }) => data.farmTenant)

    const role = useSelector(state => state.global.userClaims?.role);

    // const tenant2 = useSelector(({ firestore: { data } }) => data.Tenants && data.Tenants[id])

    useEffect(() => {
        if (farm) {
            //If farm has no farmEventsPeriod start - set it to createTime for the farm
            if (!farm.farmEventsPeriodStart) {
                const farmCreationTime = farm.createdAt;
                console.log(`FarmEventsPreiodStart not set for farm ${farm.displayName}. Setting it now to ${farmCreationTime}`);
                firebase.firestore().doc(farmPath).update({ farmEventsPeriodStart: (farmCreationTime?.seconds || 0) * 1000 });
            }
            //Only set farmEVentsPeriodStart-state when the value is actually set in firebase
            else {
                setFarmEventsPeriodStart(farm.farmEventsPeriodStart);
            }
        }
    }, [farm])

    const sessions = useSelector(({ firestore: { ordered } }) => {

        const newSes = ordered.farmSessions
            //Sort session with alarmed first
            ?.slice()?.sort(sortSessionsByAlarmed)
            //Add camera-field to all elements
            ?.map(ses => {
                const reactionMean = Math.floor(ses.reactionTimes?.length <= 0 ? -1 : ses?.reactionTimes?.reduce((prev, cur) => prev + cur, 0) / ses.reactionTimes?.length / 60);
                return { ...ses, reactionMean, tenantId, cameraState: ordered.farmCameras?.find(cam => cam.id == ses.camId)?.state }
            })
            //Reduce into sessionsObj
            .reduce(activeInactiveReducerFnc, { active: [], inactive: [] });

        return newSes;
    });

    const reactionMean = Math.floor(props?.reactionTimes?.length <= 0 ? -1 : props?.reactionTimes?.reduce((prev, cur) => prev + cur, 0) / props.reactionTimes?.length / 60);

    const farmEvents = useSelector(({ firestore: { ordered, data } }) => {
        return ordered.farmEvents || [];
    });
    const farmCameras = useSelector(({ firestore: { ordered } }) => ordered.farmCameras || []);

    const detailDataArray = farm && [
        ["Name", farm.displayName],
        ["Customer nr.", farm.customerNumber],
    ];

    const breadcrumbNamesAndLinks = role == "master" ? [
        ["Dashboard", "/"],
        ["Owners", "/Tenants"],
        [tenant?.displayName_derived, `/tenants/${tenantId}`],
        [`${farm?.displayName} (farm)`, `#`],
    ] :
        [
            ["Dashboard", "/"],
            [`${farm?.displayName} (farm)`, `#`],
        ];
    const multiSelectOtions = tenant?.users_derived?.filter(uD => uD.id != tenantId).map(uD => { return { id: uD.path, name: uD.displayName } }) || [];

    /*[
        {id: "lkj", name: "hanshanshanshanshans"},
        {id: "er", name: "Finn"},
        {id: "t44", name: "Ole"},
        {id: "dfd", name: "Sigurd"},
        {id: "tjj", name: "Hannah"},
    ];
    */

    const dispatch = useDispatch();

    const [addUsersOpen, setAddUsersOpen] = useState(false);

    const setNewUsers = (userPaths) => {
        console.log("New users: ", userPaths);
        //setAddUsersOpen(false);
        const firestore = firebase.firestore();
        const farmRef = firestore.doc(`tenants/${tenantId}/farms/${farmId}`);

        return farmRef.update({ userPaths: userPaths })
            .then(() => setAddUsersOpen(false));
    }

    const editUsers = () => {
        setAddUsersOpen(true);
    }


    const deleteFarmFnc = () => {
        const farmName = farm?.displayName;
        return firebase.firestore().doc(`tenants/${tenantId}/farms/${farmId}`).delete()
            .then(() => {
                setdeleteModalOpen(false);
                M.toast({ html: `Deleted farm '${farm.displayName}'` }, 4000)
                setTimeout(history.goBack, 500);
            })
    }

    const showEvents = (showObj) => {
        //console.log("ShowObj: ", showObj);
        setShowingSessionEvents(showObj);
    }

    const requestInterpretSession = (sessionId) => { firebase.functions().httpsCallable("interpretSession")({ sessionId, farmPath: farm.path }) };

    const combinedSessionsList = [{ heading: "Sessions - active", empty: !sessions?.active?.length > 0, active: true }, ...sessions?.active || [], { heading: "Sessions - inactive", empty: !sessions?.inactive?.length > 0, active: false }, ...sessions?.inactive || []];

    const faultEventBtnColorClass = farmEvents.length > 5 ? farmEvents.length > 10 ? colors.red : colors.yellow : colors.green;

    return (<div>
        {showingSessionEvents?.type == "session" &&
            <Modal options={{ onCloseEnd: () => setShowingSessionEvents(null) }} className="events-modal" open={showingSessionEvents != null}>
                <SessionEventsConnected tenantId={tenantId} farmId={farmId} sessionId={showingSessionEvents?.sessionId} />
            </Modal>
        }
        {showingSessionEvents?.type == "cam" &&
            <Modal options={{ onCloseEnd: () => setShowingSessionEvents(null) }} className="events-modal" open={showingSessionEvents != null}>
                <CameraEventsConnected tenantId={tenantId} cameraId={showingSessionEvents?.cameraId} />
            </Modal>
        }
        {farm?.farmEventsPeriodStart && <Modal options={{ onCloseEnd: () => setShowingFarmEvents(false) }} className="events-modal" open={showingFarmEvents}>
            <FarmEventsView farmPath={farmPath} farmEvents={farmEvents} periodStart={farm?.farmEventsPeriodStart || Date.now()} />
        </Modal>
        }
        <FTBreadcrumb namesAndLinks={breadcrumbNamesAndLinks} />
        <div className="container">
            <MultiSelectModal options={multiSelectOtions} preselectedIds={farm?.userPaths} header="Farm users" open={addUsersOpen} onSubmit={setNewUsers} close={() => setAddUsersOpen(false)} />
            <Row>
                <div style={{ display: "flex" }}>
                    <h5 style={{}}>{farm?.displayName}</h5>
                    <Button style={{ marginLeft: "2em" }} icon={<Icon>edit</Icon>} floating className="rights" onClick={editUsers} />
                    <div style={{ flex: 1 }} />
                    {role === "master" && <Button small onClick={clickedShowFaults} className={"desktop " + faultEventBtnColorClass} style={styles.faultsTotal}>
                        {farmEvents?.length} Farm Event{farmEvents?.length !== 1 && "s"}
                    </Button>}
                    <div style={{ flex: 1 }} />
                    <div className="right desktop">
                        <div><Checkbox label="Detailed View" className="right" checked={detailedMasterViewSelected}
                            value={"details"} id="details" onChange={(e) => dispatch(setShowingDetailedMaster(!detailedMasterViewSelected))} /></div>
                        <div><Checkbox label="Show All" className="right" checked={showAll}
                            value={"showAll"} id="showAll" onChange={(e) => setShowAll(!showAll)} /></div>
                    </div>
                    {farm?.activeReactionTimesMean >= 0 && <span className="reaction-time right">Reaction time ~ <span style={{ display: "inline" }} className={`${getColorForReactionTime(farm?.activeReactionTimesMean)}`}>{((farm?.activeReactionTimesMean ?? 0) / 60).toFixed(1)}</span>mins</span>}
                </div>
            </Row>

            <Divider />
            <Row className="ressemble-mobile">

                <Col s={12}>
                    <h5>Cameras</h5>
                    {/* <Collection> */}
                    <Row>
                        {farmCameras.map((cam, i) => cam.state && <span key={i} className={"cam-link "} style={{ display: "inline-block" }}>

                            <Link className={"truncate cam-link " + getCamStateColor(cam.state)} to={`/tenants/${tenantId}/farms/${farmId}/cameras/${cam.id}`}><h6 style={{ color: "black" }}>{cam.id}</h6></Link >

                        </span>)}
                    </Row>
                    {/* </Collection> */}

                    {/*["master", "tenant"].includes(userClaims?.role) ?
                            <Select multiple>
                                {farm.users_derived?.map((fU,i)=>
                                <option value={fU.path} key={i} className="selected">
                                    {fU.displayName}
                                </option>)}
                            </Select> :
                                */}
                </Col>
            </Row>
            <Divider />
            {detailedMasterViewSelected ?
                <Collapsible className={"littlePadding"} popout accordion={false}>
                    {combinedSessionsList.map(ses => {
                        const colorClass = getSessionColor(ses);
                        return (ses.heading ? <SectionHeader key={ses.heading} {...ses} /> : <CollapsibleItem expanded={ses.camSessionId == "654D"} key={ses.camSessionId} headerClassName={"less-height " + colorClass} header={<SessionHeader timeNow={timeNow} {...ses} />}>
                            {!ses.heading && <SessionDetail requestInterpretation={requestInterpretSession} showEndModal={setEndModalOpenSes} showEvents={showEvents} role={role} timeNow={timeNow} {...ses} />}
                        </CollapsibleItem>
                        )
                    })}
                </Collapsible> :
                <SessionsViewConnected focusedId={focusingId} reactionTime={farm?.activeReactionTimesMean} showInactive />
            }

            <Divider />
            <div style={{ height: "5em" }} />
            <Button small className="valign-wrapper" onClick={() => history.push(`${location.pathname}/sessions`)}>User view<Icon>chevron_right</Icon></Button>
            {/* {role === "master" && <Button small className="valign-wrapper" onClick={()=>console.log("Sending notification")}>Test Notification<Icon>chevron_right</Icon></Button>} */}
            {role === "master" && <Button small className="right red" onClick={() => setdeleteModalOpen(true)}>
                <div className={"btn-link"}>
                    Delete Farm
                    <Icon right>
                        delete
                    </Icon>
                </div>
            </Button>}
            <div style={{ height: "5em" }} />

            <ConfirmModal_Delete
                open={deleteModalOpen}
                onConfirm={deleteFarmFnc}
                onCancel={() => setdeleteModalOpen(false)}
                contentType="farm"
                contentName={farm?.displayName} />
            <EndSessionModal
                session={endModalOpenSes}
                onClose={() => setEndModalOpenSes(null)}
                open={endModalOpenSes && true}>

            </EndSessionModal>

        </div>


        <div className={"focus-overlay " + (focusingId && fadingFocusSCreen == "in" ? "fade-in" : focusingId && fadingFocusSCreen == "out" ? "fade-out" : "hidden")}></div>
    </div>)
}

const styles = {
    faultsTotal: {
        margin: "auto",
    },
}