import React from 'react'
import { useFirestore, isLoaded, isEmpty, useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'react-redux';

import {Button, Icon, Breadcrumb, Collection, CollapsibleItem, CollectionItem} from 'react-materialize';
import { Link } from 'react-router-dom';
import FTBreadcrumb from './Util/FTBreadcrumb';
import {TenantLinkCollection} from "./DynamicLinkCollections";

export default function Tenants() {

    const firestore = useFirestoreConnect (
        [
            'tenants',
            'cameras'
        ]
    );

    const tenants = useSelector(state=> state.firestore.ordered.tenants || []);

    //console.log("tenants: ", tenants)
    var subStitution = null;

    if (!isLoaded(tenants)) {
        console.log("Not LOaded")
        subStitution = <div>Loading...</div>;
      }
    
    else if (isEmpty(tenants)) {
          //console.log("Empty")
       subStitution = <div>Tenants List Is Empty</div>;
      }

    const tenantNamesAndLinks = tenants.map(t=>[t.displayName_derived, `/tenants/${t.id}`]);

    return (
        <div>
            <FTBreadcrumb namesAndLinks={[["Dashboard", "/"],["Owners", "/tenants"]]} />
            <div className="container">
                <h1>Owners</h1>
                <TenantLinkCollection new canAdd nameAndLinkTuples={tenantNamesAndLinks} />
            </div>
        </div>
    )
}
