import React, { useState, useCallback, useEffect } from 'react'
import { useFirestore, isLoaded, isEmpty, useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'react-redux'

import { Button, Icon, Breadcrumb, Collection, CollapsibleItem, CollectionItem } from 'react-materialize'
import { Link } from 'react-router-dom'
import FTBreadcrumb from './Util/FTBreadcrumb'
import { ApiKeyLinkCollection } from './DynamicLinkCollections'
import { ApiKeyDataModal } from './Util/ModalForms'

import firebase, { firestore } from 'firebase/app'

export default function APIKeys () {
  const firestore = useFirestoreConnect(
    [
      'apiKeys'
    ]
  )

  const apiKeys = useSelector(state => state.firestore.ordered.apiKeys || [])

  const [apiKeyEdited, setApiKeyEdited] = useState(undefined) // "-1" means open modal to create new api key

  const deleteOpenedApiKey = useCallback(() => {
    console.log('Deleting')
    const firestore = firebase.firestore()
    const apiKeyRef = firestore.doc(`apiKeys/${apiKeyEdited}`)
    apiKeyRef.delete()
    setApiKeyEdited(undefined)
  }, [apiKeyEdited])

  return (
        <div>
            <FTBreadcrumb namesAndLinks={[['Dashboard', '/'], ['API Keys', '/apiKeys']]} />
            {apiKeyEdited !== undefined && <ApiKeyDataModal isNew={apiKeyEdited === -1} onDelete={deleteOpenedApiKey} open={apiKeyEdited !== undefined} onClose={() => setApiKeyEdited(undefined)} apiKeyId={apiKeyEdited} close={() => setApiKeyEdited(undefined)}/>}
            <div className="container">
                <h1>API Keys</h1>

                <Collection>
                {
                    apiKeys.map(apiKey => <CollectionItem key={apiKey.id}>
                        <div onClick={() => setApiKeyEdited(apiKey.id)} className="text-btn">{apiKey.displayName}</div>
                    </CollectionItem>)
                }
                </Collection>
                <Button floating icon={<Icon>add</Icon>} onClick={() => setApiKeyEdited(-1)} />
            </div>
        </div>
  )
}
