
import React, { useEffect, useState } from 'react'
import {Collection, CollectionItem, Pagination, Icon, Collapsible, CollapsibleItem, Col, Row, Checkbox} from 'react-materialize';
import { useFirestoreConnect } from 'react-redux-firebase';
import { withFirestore } from 'react-redux-firebase'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux';
import {EventHeader, EventDetail} from './Util/eventVisualisation';

import {vars} from "./../vars";
import FTBreadcrumb from './Util/FTBreadcrumb';
import { firestore } from 'firebase';

export const SessionEventsViewFromLocation = (props)=>{
    const {tenantId, farmId/*, cameraId*/, sessionId} = useParams();
    return SessionEventsConnected({tenantId, farmId/*, cameraId*/, sessionId})
}

const SessionEventsView = (props) => {
    
    //console.log("sessionEVentsview props: ", props);
    const tenantId = props.tenantId;
    const farmId = props.farmId;
    const sessionId = props.sessionId;
    const archived = props.archived;
    const eventsPrPage = 20;
    const [page, setPage] = useState(1);
    const [timeNow, setTimeNow] = useState(Date.now());
    
    const [sortByCamTime, setSortByCamTime] = useState(false);

    useEffect(()=>{
        if(archived)
        {
            props.firestore.get({collection:`tenants/${tenantId}/farms/${farmId}/sessions_archived`, doc:sessionId, storeAs:"session"});
            props.firestore.get({collection:`tenants/${tenantId}/farms/${farmId}/sessions_archived/${sessionId}/${vars.DB.TABLES.EVENTS_OWNED}`, orderBy: [sortByCamTime ? "timestamp_server" : "timestamp", "desc"], storeAs:"events"});
        }
        else {
            props.firestore.get({collection:`tenants/${tenantId}/farms/${farmId}/sessions`, doc:sessionId, storeAs:"session"});
            props.firestore.get({collection:`tenants/${tenantId}/farms/${farmId}/sessions/${sessionId}/${vars.DB.TABLES.EVENTS_OWNED}`, orderBy: [sortByCamTime ? "timestamp_server" : "timestamp", "desc"], storeAs:"events"});
        }
       // props.firestore.get({collection:`cameras`, doc:cameraId, storeAs:"camera"});
        //props.firestore.get({collection:`tenants/${tenantId}/farms`, doc:farmId, storeAs:"farm"});
        
    }, [sortByCamTime]);
    
    //const camera = useSelector(state=>state.firestore.data.camera);
    //const farm = useSelector(state=>state.firestore.data.farm);
    
    const session = useSelector(state=>state.firestore.data.session);
    const evCount = useSelector(state=>state.firestore.ordered.events?.length) || 0;
    const pageCount = Math.ceil(evCount / eventsPrPage);
    const startEv = (page - 1) * eventsPrPage;
    const events = useSelector(state=>state.firestore.ordered.events?.slice(startEv, startEv + eventsPrPage) || []);
    //console.log("session id: ", evCount);

    const role = useSelector(state=>state.global.userClaims.role);

    console.log("events: ", events);
 
    const selectPage = (nr) =>{
        console.log("Set page to: ", nr);
        setPage(nr);
    }
    /*
    const breadcrumbNamesAndLinks = role == "master" ?
    [
        ["Dashboard", "/"],
        ["Tenants", "/Tenants"],
        //[camera?.tenant_derived.displayName, `/tenants/${tenantId}`],
        [`${farm?.displayName} (farm)`, `/tenants/${tenantId}/farms/${farmId}`],
        [`session ${sessionId} (events)`, `#`],
    ] : 
    [   
        ["Dashboard", "/"],
        [`${farm?.displayName} (farm)`, `/tenants/${tenantId}/farms/${farmId}`],
        [`session ${sessionId}`, `#`],
    ];
*/
    return (<div>
        {/* <FTBreadcrumb namesAndLinks={breadcrumbNamesAndLinks}/> */}
        <div className="containers">
            {events.length == 0 ? <h5 className={"center-align"}>No events</h5>:
            <div>
                <h4>
                    
                            Events for session {sessionId} 
                    
                    <span className="right" m={4}><h6>Pen: {session?.penNumber} Sow: {session?.sowNumber}</h6></span>
                </h4>
                
                <div className="center-align">
                    <Pagination
                        activePage={page}
                        items={pageCount}
                        onSelect={selectPage}
                        leftBtn={<Icon>chevron_left</Icon>}
                        maxButtons={8}
                        rightBtn={<Icon>chevron_right</Icon>}
                    />
                </div>
                <div>
                    &emsp;
                    <div className="right">

                        Sort by:&emsp;
                    <Checkbox id="useCamTime" filledIn className="right 2" checked={sortByCamTime} value={"Some Value 2"} onChange={() => setSortByCamTime(!sortByCamTime)} label="Cam Time" />
                    </div>
                </div>
                <Collapsible accordion={false} >
                    {events?.map((e, i)=>{
                        const eventKeyValTuples = Object.entries(e);
                        return <CollapsibleItem headerClassName="less-height" key={startEv + i}
                        header={<EventHeader {...{...e, name: new Date(e.timestamp).toLocaleString("en-GB"), timeNow:timeNow}}/>}>
                            <EventDetail keyValTupleArray={eventKeyValTuples}/>
                        </CollapsibleItem>})}
                </Collapsible>
                <div className="center-align">
                    <Pagination
                        activePage={page}
                        items={pageCount}
                        onSelect={selectPage}
                        leftBtn={<Icon>chevron_left</Icon>}
                        maxButtons={8}
                        rightBtn={<Icon>chevron_right</Icon>}
                    />
                </div>
            </div>}
        </div>
    </div>);
}

export const SessionEventsConnected = withFirestore(SessionEventsView);

