const initialState = [
    {id: 9872347, name: "Johns gård"},
    {id: 972347, name: "Povls gård"},
    {id: 987347, name: "Erikss gård"},
    {id: 987234, name: "Hanness gård"},
];

export default (state = initialState, { type, payload }) => {
    switch (type) {

    case "SomeValue":
        return { ...state, ...payload }

    default:
        return state
    }
}
