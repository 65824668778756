import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Modal, Button, TextInput, Icon, Preloader, Select, Col, Row, Divider } from 'react-materialize';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'react-redux';
import M from "materialize-css";
import { withFirestore } from 'react-redux-firebase'
import { vars } from "./../../vars";
import { getFirebase, getFirestore } from 'react-redux-firebase';
import { KeyValGrid } from './KeyValGrid';
import { MultiSelectModal } from './MultiSelectModal';
import * as manualEvents from "./../../modules/events";
import firebase, { firestore } from 'firebase/app';
import mergeProps from 'react-redux/lib/connect/mergeProps';
const trigger = <Button>Open Modal</Button>;

function makeid(length) {
    var result = '';
    var characters = 'abcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const DarkOverlay = (props) => {
    const style = { position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "black", opacity: 0.5 };

    if(props.zIndex)
    {
        style.zIndex = props.zIndex;
    }
    return (
        <div style={style}>
            {!props.spinner ? null :
                <div className="centre-elem" >
                    <Preloader
                        active
                        color="blue"
                        flashing={false}
                        size="small"
                    />
                </div>
            }
        </div>
    )
}
export const SubmitBtn = (props) => {
    return (
        <Button {...props} type="submit">
            Submit
            <Icon right>
                send
            </Icon>
        </Button>
    )
}

export const UserDataModal = props => {

    const [displayName, setDisplayName] = useState("");

    const [email, setEmail] = useState("");

    const [password, setPassword] = useState(makeid(6));

    const [submitting, setSubmitting] = useState(false);

    const tenantPath = props?.tenantId && "tenants/" + props?.tenantId;

    const resetState = () => {
        setEmail("");
        setDisplayName("");
        setPassword(makeid(6));
    }
    useEffect(resetState, [props.open]);
    //useEffect(resetState,[]);

    const addNewUserFunc = () => {
        const firebaseGotten = getFirebase();
        const dataObj = { displayName, email, password, ...props.addedFields };

        if (tenantPath)
            dataObj.tenantPath = tenantPath;

        console.log("New user Data Object: ", dataObj);
        setSubmitting(true);
        //setTimeout(()=>setSubmitting(false), 1000);
        const addFnc = props.isTenant ? getFirebase().functions().httpsCallable("addTenant") : getFirebase().functions().httpsCallable("addUser");

        addFnc(dataObj)
            .then((result) => {
                console.log("Submit succeeded with result object: ", result);
                setSubmitting(false);
                props.close();
            })
            .catch((e => {
                setSubmitting(false);
                M.toast({ html: `Submit error: ${e}` })
                console.log("Got submit error: ", e);
            }))
            .then(() => {
            })
        //const firestore = getFirestore();
        //const userDocRef firestore.collection(vars.DB.USERS).doc();
    }
    return (


        <Modal
            header={(props.new ? "Add new " : "Edit ") + (props.isTenant ? "tenant" : "user")}
            className={submitting ? "semi-transp" : ""}
            open={props.open}
            options={{ onCloseEnd: props.close }}
            actions={[
                <Button disabled={submitting} flat modal="close" node="button" >Cancel</Button>,
                <SubmitBtn disabled={submitting} className="teal" node="button" type="submit" onClick={addNewUserFunc} />
            ]}
        >

            <TextInput disabled={submitting} id="displayName" value={displayName} label="displayName" onChange={e => setDisplayName(e.currentTarget.value)} />
            <TextInput disabled={submitting} id="email" value={email} email validate label="email" onChange={e => setEmail(e.currentTarget.value)} />
            <TextInput disabled={true} id="password" value={password} label="password" />
            {submitting ? <DarkOverlay spinner /> : null}
        </Modal>
    );
}


export const ViewUserModal = ({ userId, open, onClose, onDelete }) => {

    useFirestoreConnect(userId && {
        collection: "users",
        doc: userId
    })

    const firestore = useFirestore();

    const userData = useSelector(({ firestore: { data } }) => userId && data?.users ? data?.users[userId] : {});

    const [deleteOpen, setDeleteOpen] = useState(false);

    const loggedInUserRole = useSelector(state => state.global.userClaims.role);

    //console.log("User Modal data2: ", userData);
    //console.log(`Logged in user role: ${loggedInUserRole}`)

    const confirmDelete = () => {
        return onDelete()
            .then(() => setDeleteOpen(false))
        //.then(()=>setTimeout(onClose, 500));
    }
    const detailDataArray = [
        ["Name", userData?.displayName],
        ["Email", userData?.email],
        ["Original Password", userData?.originalPassword]
        // ["Created at", new Date(tenant.createdAt.seconds * 1000).toTimeString()],
        //["Owner Id", tenant.ownerId],
    ];
    return (

        <div>

            <Modal
                header="User"
                //className = {submitting ? "semi-transp" : ""}
                open={open}
                options={{ onCloseEnd: onClose }}
                actions={[
                    <Button onClick={() => setDeleteOpen(true)} className="left red">Delete</Button>,
                    <Button flat modal="close" node="button" >Back</Button>

                ]}
            >

                <KeyValGrid propAndValuePairArray={detailDataArray} />
                <div>ælkjælkj</div>
            </Modal>
            <ConfirmModal onConfirm={confirmDelete} onCancel={() => setDeleteOpen(false)} confirmRed open={deleteOpen}>
                <ConfirmDelete contentType="User" contentName={userData?.displayName} />
            </ConfirmModal>

        </div>
    );
}

export const FarmDataModal = props => {

    const thisSelectInstance = useRef();
    const selectedUsers = useRef();
    const [displayName, setDisplayName] = useState("");
    const [submitting, setSubmitting] = useState(false);


    const addFarmFunc = () => {

        const dataObj = { displayName, userPaths: selectedUsers.current || [], ...props.addedFields };


        setSubmitting(true);

        //console.log("Add farm: ", dataObj);

        //console.log("Tenant id: ", props.tenantId);
        const firestore = firebase.firestore();
        const farmRef = firestore.collection(`tenants/${props.tenantId}/farms`).doc();
        //console.log("farm path : ", farmRef.path);

        farmRef.set(dataObj)
            .then(() => {
                props.close();
                console.log("Farm add successful");
            })
            .catch(e => console.log(`got error ${e} during submission of new farm at path:`, farmRef.path))
            .then(() => {
                console.log("Done submitting");
                setSubmitting(false)
            });
    }


    return (


        <Modal
            className="edit-modal"
            header={props.new ? "Add Farm" : "Edit Farm"}
            open={props.open}
            options={{ onCloseEnd: props.close, dismissible: false, preventScrolling: true }}
            actions={[
                <Button disabled={submitting} flat modal="close" node="button" waves="green">Cancel</Button>,
                <SubmitBtn disabled={submitting} node="button" waves="light" type="submit" onClick={addFarmFunc} />
            ]}
        >
            <div >
                <TextInput disabled={submitting} label="displayName" onChange={e => setDisplayName(e.currentTarget.value)} />


                {submitting ? <DarkOverlay spinner /> : null}
            </div>
        </Modal>
    );
}


export const ApiKeyDataModal = ({ apiKeyId, isNew, open, close, onClose, onDelete }) => {

    const firestore = useFirestoreConnect (
        [
            'apiKeys',
            'tenants',
        ]
    );
    //const thisSelectInstance = useRef();
    //const selectedUsers = useRef();
    const [submitting, setSubmitting] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [assignTenantsOpen, setAssignTenantsOpen] = useState(false);

    const [displayName, setDisplayName] = useState("");
    const [tenantsState, setTenantsState] = useState([]);

    
    const apiKeyData = useSelector(({ firestore: { data } }) => apiKeyId && data?.apiKeys ? data?.apiKeys[apiKeyId] : {});
    // Set tenants state, when api key data updates
    useEffect(()=>setTenantsState(apiKeyData?.tenants || []), [apiKeyData?.tenants]);
    
    const tenantsData = useSelector(({ firestore: { ordered } }) => ordered.tenants) ||[];

    const editTenants = useCallback(()=>setAssignTenantsOpen(true));
   
    useEffect(()=>{
        setDisplayName(apiKeyData?.displayName);
    }, [apiKeyData?.displayName])

    const confirmDelete = () => {
        return onDelete()
            .then(() => setDeleteOpen(false))
    }

    const detailDataArray = [
        ["API Key", apiKeyId !== -1 ? apiKeyId : "Will be generated"],
        ["Secret", apiKeyData?.secret || "Will be generated"],
    ];
    const setAPIKeyFunc = () => {


        setSubmitting(true);

        const firestore = firebase.firestore();
        const apiKeyRef = isNew ? firestore.collection(`apiKeys`).doc() : firestore.collection(`apiKeys`).doc(apiKeyId);

        apiKeyRef.set({displayName, tenants:tenantsState}, {merge:true})
            .then(() => {
                console.log("API Key add successful");
            })
            .catch(e => console.log(`got error ${e} during submission of new API key at path:`, apiKeyRef.path))
            .then(() => {
                console.log("Done submitting");
                setSubmitting(false);
                onClose();
            });
    }

    const registerTenantsSelection = useCallback((newTenantIds)=>{
        setTenantsState(newTenantIds);
        setAssignTenantsOpen(false);
    }, []);
    
    return (

        <div>

            <Modal
                header="API Key"
                open={open}
                options={{ onCloseEnd: onClose }}
                actions={isNew ? [
                    <Button flat modal="close" node="button" >Back</Button>,
                    <SubmitBtn disabled={submitting} className="teal" node="button" type="submit" onClick={setAPIKeyFunc} /> 
                    ]
                        :
                    [
                        <Button onClick={() => setDeleteOpen(true)} className="left red">Delete</Button>,
                        <Button flat modal="close" node="button" >Back</Button>,
                        <SubmitBtn disabled={submitting} className="teal" node="button" type="submit" onClick={setAPIKeyFunc} /> 
                    ]}
            >
                    <TextInput value={displayName} disabled={submitting} label="Display Name" onChange={e => setDisplayName(e.currentTarget.value)} />

                <KeyValGrid propAndValuePairArray={detailDataArray} />
                <Row onClick={()=>{}}>
                    <Col>
                    {tenantsState.map((tId)=>tenantsData.find(t=>t.id===tId)?.displayName_derived).join(", ")}
                    </Col>
                    <Col m={4}/>
                    <Col>
                    <Button onClick={editTenants}>Edit</Button>
                    </Col>
                </Row>
            </Modal>
            <ConfirmModal onConfirm={confirmDelete} onCancel={() => setDeleteOpen(false)} confirmRed open={deleteOpen}>
                <ConfirmDelete contentType="API Key" contentName={apiKeyData?.displayName} />
            </ConfirmModal>
            <MultiSelectModal
                options={tenantsData.map(tenant=>{return {id:tenant.id, name:tenant.displayName_derived}})}
                preselectedIds={tenantsState || []}
                header={`Aceess to tenants`}
                open={assignTenantsOpen}
                onSubmit={registerTenantsSelection}
                close={()=>setAssignTenantsOpen(false)} />
        </div>
    );
}



const CameraDataModal = ({ open, onClose, fixedCamId, fixedTenantId, firestore, defaultTenantPath, defaultFarmPath }) => {

    const [tenants, setTenants] = useState(null)
    const [farmsForTenants, setfarmsForTenants] = useState(null);
    const [submitting, setSubmitting] = useState(false)
    const [selectedTenantPath, setselectedTenantPath] = useState(defaultTenantPath || "");
    const [selectedFarmPath, setselectedFarmPath] = useState(defaultFarmPath);
    const [cameraId, setcameraId] = useState(fixedCamId || "");

    const getDataWithPathFromDocSnap = (docSnap) => { return { ...docSnap.data(), path: docSnap.ref.path } };
    const isNew = !fixedCamId;

    useEffect(() => {

        if (fixedTenantId) {
            const tenantPath = `tenants/${fixedTenantId}`
            firestore.get(tenantPath).then(res => {
                const tenantWithPath = getDataWithPathFromDocSnap(res);
                setTenants([tenantWithPath]);
            })
                .catch(e => console.log("Got firestore error during tenant get:", e));

            firestore.get(`tenants/${fixedTenantId}/farms`).then(res => {
                const farmsWithPaths = res.docs.map(doc => {
                    return getDataWithPathFromDocSnap(doc);
                })
                //console.log("FarmsWIth PAths: ", farmsWithPaths);
                setfarmsForTenants({ [tenantPath]: farmsWithPaths });
            })
                .catch(e => console.log("Got firestore error during tenant get:", e));
        }
        else {

            firestore.get("tenants")
                .then(res => {
                    const tenantsArr = res.docs.map(doc => getDataWithPathFromDocSnap(doc));
                    setTenants(tenantsArr);
                    const farmsForTenantPaths = {};
                    tenantsArr.forEach(t => farmsForTenantPaths[t.path] = []);
                    //console.log("Performing collection group query")
                    return firestore.collectionGroup("farms").get()
                        .then(qDocSnap => {
                            //console.log("COllection goroup query done");
                            qDocSnap.docs.forEach((doc, i) => farmsForTenantPaths[doc.ref.parent.parent.path].push(getDataWithPathFromDocSnap(doc)));
                            setfarmsForTenants(farmsForTenantPaths);

                        })
                })
        }
    }, []);

    useEffect(() => {
        //console.log("Tenants: ", tenants);
        //console.log("farms for tenant paths: ", farmsForTenants);
        if (tenants && !tenants?.find((t => t.path == selectedTenantPath))) {
            setselectedTenantPath(tenants?.length > 0 ? tenants[0].path : "");
            setselectedFarmPath("");
        }
        else if (farmsForTenants?.hasOwnProperty(selectedTenantPath) && !farmsForTenants[selectedTenantPath].find(f => f.path == selectedFarmPath)) {
            console.log(`Selected farm not found. TenantPath: ${selectedTenantPath} and farmsForTenants: `, farmsForTenants);
            setselectedFarmPath(farmsForTenants[selectedTenantPath]?.length > 0 ? farmsForTenants[selectedTenantPath][0].path : "");
        }

    }, [farmsForTenants, tenants])

    const farmsForTenant = farmsForTenants?.hasOwnProperty(selectedTenantPath) && farmsForTenants[selectedTenantPath];
    //  console.log("Selected tenant path: ", selectedTenantPath);
    // console.log("Selected farm path: ", selectedFarmPath);
    //console.log("Farms for tenants: ", farmsForTenants);
    // console.log("Farms for tenant: ", farmsForTenant);
    // console.log("Tenants: ", tenants);

    const addCameraFunc = async () => {

        const dataObj = { cameraId, tenantPath: selectedTenantPath, farmPath: selectedFarmPath };


        if (dataObj.cameraId == "" || dataObj.tenantPath == "") {
            M.toast({ html: "caamera Id and Tenant must be set" }, 4000);
            return;
        }
        setSubmitting(true);

        //console.log("Tenant id: ", props.tenantId);
        const firestore = firebase.firestore();
        const camRef = firestore.collection(`cameras`).doc(cameraId);
        var existed = false;

        await camRef.get()
            .then(farmDocSnap => {
                existed = farmDocSnap.exists;
            })
        //console.log("farm path : ", farmRef.path);

        if (existed && isNew) {
            M.toast({ html: "Cannot add camera with pre-existing id" }, 3000)
            setSubmitting(false);
            return;
        }

        console.log(`Commiting '${camRef.path}' data object: `, dataObj);

        camRef.set(dataObj, { merge: true })
            .then(() => {
                M.toast({ html: `Camera with id '${dataObj.cameraId}' ${isNew ? " added " : " edited "}` })
            })
            .catch(e => {
                console.log(`got error ${e} during submission of new camera at path:`, camRef.path);
                M.toast({ html: "Error adding camera: " + e })
            })
            .then(() => {
                console.log("Done submitting");
                setSubmitting(false)
                onClose();
            });

    }

    const setSelectedTanantAndFarmPath = (tenantPath) => {
        setselectedTenantPath(tenantPath);
        const newFarmPath = farmsForTenants[tenantPath].length > 0 ? farmsForTenants[tenantPath][0].path : "";
        // console.log("Auto farm path: ", newFarmPath);
        setselectedFarmPath(newFarmPath);
    }

    //console.log(`selected tenantPath: ${selectedTenantPath} farm: ${selectedFarmPath}`);
    return (


        <Modal
            className="edit-modal"
            header={!fixedCamId ? "Add Camera" : "Edit Camera"}
            open={open}
            options={{ onCloseEnd: onClose, dismissible: false, preventScrolling: true }}
            actions={[
                <Button disabled={submitting} flat modal="close" node="button" waves="green">Cancel</Button>,
                <SubmitBtn disabled={submitting} node="button" waves="light" type="submit" onClick={addCameraFunc} />
            ]}
        >
            <div >
                <TextInput value={cameraId} disabled={submitting || fixedCamId != undefined} label="Camera Id" onChange={e => setcameraId(e.currentTarget.value)} />

                {tenants && <Select disabled={!!fixedTenantId} value={selectedTenantPath} options={{ dropdownOptions: { autoTrigger: true } }} onChange={(e) => setSelectedTanantAndFarmPath(e.currentTarget?.value)}>
                    <option key="0" disabled value={""}>Choose Tenant</option>
                    {tenants?.map(t =>
                        <option key={t.path} value={t.path}>
                            {t.displayName_derived}
                        </option>
                    )}
                </Select>}

                {farmsForTenant && <Select key={selectedTenantPath} value={selectedFarmPath} options={{ dropdownOptions: { autoTrigger: true } }} onChange={(e) => setselectedFarmPath(e.currentTarget?.value)}>
                    <option key="0" disabled value={""}>Choose Farm</option>
                    {farmsForTenant?.map(t =>
                        <option key={t.path} value={t.path}>
                            {t.displayName}
                        </option>
                    )}
                </Select>}



                {submitting ? <DarkOverlay spinner /> : null}
            </div>
        </Modal>
    );

}
export const CameraDataModalWithFirestore = withFirestore(CameraDataModal);

export const TempMessageModal = ({message, open, waiting}) =>
                /*
                <div>
                    <DarkOverlay zIndex={99} spinner/>
            <Modal className="centre-50 semi-transp" actions={[]} open={open} options={{dismissible:false, preventScrolling:false}}>
                <div className="centre-elem" >
                    Hejsa
                </div>
                </Modal>
                    </div>
                    */
        <div style={{position:"fixed", top:"0px", width:"100%", height:"100%", zIndex:99}}>
            <DarkOverlay spinner zIndex={99}>
            
        </DarkOverlay>
            </div>
    /*
    </Modal>
*/

export const ConfirmModal = ({ title, content, onConfirm, onCancel, children, open, confirmRed }) => {
    const [submitting, setSubmitting] = useState(false);

    const confirmFnc = () => {
        setSubmitting(true);
        onConfirm()
            .catch(e => M.toast({ html: `Received error: '${e}' when confirming` }, 4000))
            .then(() => setSubmitting(false))
            .then(() => onCancel())
    }
    return (
        <Modal

            className={"centre-50 " + (submitting ? "semi-transp" : "")}
            open={open}
            options={{ dismissble: false, onCloseEnd: onCancel }}
            actions={[
                <Button small className="left" disabled={submitting} flat modal="close" node="button" onClick={onCancel}>Cancel</Button>,
                <Button small className={"right" + (confirmRed && " red")} disabled={submitting} node="button" waves="light" type="submit" onClick={confirmFnc}>Confirm</Button>
            ]}
        >
            {children}
            {submitting ? <DarkOverlay spinner /> : null}
        </Modal>
    )
}

const ConfirmDelete = ({ contentType, contentName }) => <div className="center-align"><span>Delete {contentType}:</span><h6>{contentName}</h6><h5>?</h5></div>

export const ConfirmModal_Delete = (props) => {
    return <ConfirmModal {...props} confirmRed>
        <ConfirmDelete contentType={props.contentType} contentName={props.contentName} />
    </ConfirmModal>
}

export const EndSessionModal = ({ session, open, onClose }) => {

    const [pigletsBornDead, setPigletsBornDead] = useState(/*session?.state?.pigletsConfirmedDead || -1*/0);
    const [pigletsBornAlive, setPigletsBornAlive] = useState(/*(session?.state?.numberInLitter - session?.state?.pigletsConfirmedDead || -1)*/0);

    //Update nubmer fields when data changes.
    useEffect(() => {
        //setPigletsBornDead(session?.state?.pigletsConfirmedDead);
        //setPigletsBornAlive(session?.state?.numberInLitter - session?.state?.pigletsConfirmedDead);
    }, [session?.state?.pigletsConfirmedDead, session?.state?.numberInLitter])

    const performManualEnd = async () => {
        //console.log("Performing end for ses: ", session);

        //console.log("Dead: ", pigletsBornDead)
        const firestore = firebase.firestore();
        const evRef = firestore.collection(`cameras/${session.camId}/events`).doc();
        M.toast({ html: "Inserting manual end" });
        const ev = manualEvents.getManualEndEvent({
            cameraId: session.camId,
            sessionId: session.camSessionId,
            pigletsBornDead: Number.parseInt(pigletsBornDead),
            pigletsBornAlive: Number.parseInt(pigletsBornAlive)
        })
        // console.log("Setting event: ", ev)

        await evRef.set(ev)
            .then(() => {
                M.toast({ html: "Manual end inserted" });
            })
            .catch((e) => {
                console.log("Manual end error: ", e);
            })

        onClose();

    }

    return <ConfirmModal onConfirm={performManualEnd} onCloseEnd={onClose} onCancel={onClose} open={open} contentType="Manual End Session">
        <div className="center-align"><h5>End Session '{session?.camSessionId}' ?</h5></div>
        <Row>
            <Col m={4} />
            {session && <Col m={4}>
                <TextInput type="number" label="Living piglets" onChange={(e) => setPigletsBornAlive(Number.parseInt(e.currentTarget.value))} value={pigletsBornAlive} />
                <TextInput type="number" label="Dead piglets" onChange={(e) => setPigletsBornDead(Number.parseInt(e.currentTarget.value))} value={pigletsBornDead} />
            </Col>}
            <Col m={4} />
        </Row>

    </ConfirmModal>
}