import React, { useState } from 'react'
import { useFirestore, isLoaded, isEmpty, useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import FTBreadcrumb from './Util/FTBreadcrumb';
import { Button, Icon, Breadcrumb, Collection, CollectionItem, Row, Col, Table, Toast, Badge } from 'react-materialize';
import { LinkCollection } from "./Util/LinkCollection";
import { CameraDataModalWithFirestore } from "./Util/ModalForms";

import { getCamStateColor } from "./Util/inferStyles";

export const Cameras = () => {

    const firestore = useFirestoreConnect(
        [
            {
                collection: 'cameras',
                orderBy: ["farmPath"],

            }
        ]
    );

    var subStitution = null;

    const [addCameraOpen, setAddCameraOpen] = useState(false);

    const cameras = useSelector(state => state.firestore.ordered.cameras);

    if (!isLoaded(cameras)) {
        //console.log("Not LOaded")
        subStitution = <div>Loading...</div>;
    }

    else if (isEmpty(cameras)) {
        console.log("Empty")
        subStitution = <div>No Cameras</div>;
    }

    const breadcrumbNamesAndLinks =
        [
            ["Dashboard", "/"],
            ["Cameras", "/cameras"]
        ];

       
       
    return (<div>
        <FTBreadcrumb namesAndLinks={breadcrumbNamesAndLinks} />

        <div className="container">
            {addCameraOpen && <CameraDataModalWithFirestore open={addCameraOpen} onClose={() => setAddCameraOpen(false)} />}
            <h4>Cameras</h4>
            <Table>
                <thead>
                    <tr>
                        <th>Camera Id</th>
                        <th>Owner</th>
                        <th>Farm</th>
                        <th>Software</th>
                        <th style={{width:"3em"}}>State</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>

                    {cameras?.map((cam) =>
                        <tr key={cam.id}>
                            <td ><Link to={`/cameras/${cam.id}`}>{cam.id}</Link></td>
                            <td ><Link to={`${cam.tenantPath}`}>{cam.tenant_derived?.displayName}</Link></td>
                            <td ><Link to={`${cam.farmPath}`}>{cam.farm_derived?.displayName}</Link></td>
                            <td>{cam.state?.executableRevision?.split(":").map(part=>part.substring(0,6)).join(" ")}</td>
                            <td>{cam.state?.camState}</td>
                            <td className={cam.state && getCamStateColor(cam.state)} style={{width: "2em", height:"100%"}}></td>
                        </tr>
                    )}
                </tbody>
            </Table>
            <div style={{ height: "1em" }} />
            <Button floating onClick={() => setAddCameraOpen(true)}>
                <Icon>add</Icon>
            </Button>
        </div>
    </div>
    )
}
