import React, { useState, useRef, useEffect } from 'react'
import { Modal, Button, TextInput, Icon, Preloader, Select, Col, Row, Checkbox } from 'react-materialize'
import M from 'materialize-css'

import { vars } from './../../vars'
import { getFirebase } from 'react-redux-firebase'

import firebase, { firestore } from 'firebase/app'
import { SubmitBtn, DarkOverlay } from './ModalForms'

export const MultiSelectModal = (props) => {
  const getOptionsWithChecked = (options, checked) => {
    // console.log("Calculating options with selected: ", options);
    return options.map(opt => {
      return { ...opt, checked: checked.includes(opt.id) }
    })
  }
  const [optionWithSelected, setOptionWithSelected] = useState([])

  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    setOptionWithSelected(getOptionsWithChecked(props.options, props.preselectedIds || []))
  }, [props.preselectedIds, props.options])

  // console.log("Multi select options: ", props.options);
  // console.log("Multi select options With Selected: ", optionWithSelected);
  const submitSelection = () => {
    setSubmitting(true)
    Promise.resolve().then(() => { // Make "empty promise" to allow for props.onSUbmit to be both promise and non-promise functions
      return props.onSubmit(optionWithSelected.filter(o => o.checked).map(o => o.id))
    })
      .then(() => setSubmitting(false))
      .catch(e => {
        setSubmitting(false)
        console.log('Got submit error: ', e)
      })
  }

  const onChange = (e) => {
    const nowChecked = e.currentTarget.checked
    const id = e.currentTarget.value

    setOptionWithSelected(optionWithSelected.map(opt => opt.id == id ? { ...opt, checked: nowChecked } : opt))
    // console.log("On change checked: ", e.currentTarget.checked);
    // console.log("On change ev: ", e.currentTarget);
  }

  // console.log("opt with select: ", optionWithSelected);
  return (

        <Modal
            className="edit-modal"
            header= {props.header}
            open={props.open}
            options={{ onCloseEnd: props.close, dismissible: false, preventScrolling: true }}
            actions={[
                <Button disabled ={submitting} flat modal="close" node="button">Cancel</Button>,
                <SubmitBtn disabled ={submitting} node="button" type="submit" onClick={submitSelection} />
            ]}
            >
                <Row>

            {optionWithSelected.map(opt => {
              return (
                <Col key={opt.id} m={4} s={6}>
                    <Checkbox id={'Multi-' + opt.id}filledIn={true} label={opt.name} value={opt.id} checked={opt.checked} onChange={onChange}/>
                </Col>
              )
            })}
            </Row>
            {submitting ? <DarkOverlay spinner/> : null}
        </Modal>
  )
}
