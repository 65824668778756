import React from 'react'
import { Row, Col} from 'react-materialize';

import { Link } from 'react-router-dom';


export const KeyValGrid = ({propAndValuePairArray, classesForIndices = null}) => {

    return(
        <div>
            {propAndValuePairArray.map((propAndValuePair, i)=>{
               const specialClass = classesForIndices?.find(tuple=>tuple[0] == i);
               //console.log("Prop and value: ", propAndValuePair)
               //Is the value part a link? then surround with link
               const LinkClass = ({children})=> propAndValuePair?.length > 2 && propAndValuePair[2] ?
               <Link to={`/${propAndValuePair[2]}`}>{children}</Link> :
               <div>{children}</div>
            
               return (
                <Row key={propAndValuePair[0]}>
                    <Col m={5} >{propAndValuePair[0]}</Col>
                    <LinkClass>
                        <Col  className={"valign-wrapper " + ((specialClass && typeof specialClass[1] === "string") ? specialClass[1] : "")}>
                            {propAndValuePair[1]}
                            {(specialClass && typeof specialClass[1] === "object") && specialClass[1]}
                        </Col>
                    </LinkClass>
                </Row>
                )
            })}
        </div>
    );
}

//

const addLeadingChars = (str, count, char = " ") =>{
    var prefix = "";
    for(var i = 0; i < count; i++)
    {
        prefix += char;
    }
    //console.log("Producing key with prefix: " + prefix + " and count: ", count);
    return prefix + str;
}
export const ObjectGrid = ({dataObj, depth = 0}) => {


    return(
        <div>
            {Object.entries(dataObj).map(([key, value], i)=>{
                const valueIsObj = typeof value == "object";
                const valueIsArray = Array.isArray(value);
                const valueIsString = typeof value == "string";
                //console.log("Object grid entry: ", [key, value]);
                if(!valueIsObj && !valueIsString && !valueIsArray)
                {
                    console.log("ObjectGrid dataObj-values must be either object, array or string. It was: ", value);
                }
                if(valueIsString)
                {
                    console.log("Value is string: ", value);
                    const keyWIthPRefix = key;//addLeadingChars(key, depth * 3, ". . ");
                    return(
                    <Row key={key} >
                        <Col s={6} m={3} /*style={{paddingLeft:0 + "em"}}*/>
                            <div style={{paddingLeft:depth + "em"}}>
                            
                            {/*(depth > 0 ? "\u21B3 " : "") + */keyWIthPRefix}
                            </div>
                        </Col>
                        <Col m={6}>{value}</Col>
                    </Row>)
                }
                else if(valueIsObj)
                {
                    return ( <div key ={key} className={"grey lighten-" + (5-depth)}>
                        <Row key={key} className="much-much-less-height">
                           
                            <Col m={12} ><div style={{paddingLeft:depth + "em"}}>{key}</div></Col>
                        </Row> 
                        <ObjectGrid className={"darken-" + depth} key={key + "_children"} dataObj={value} depth={depth +1}/>
                        </div>)
                }
                else if(valueIsArray)
                {
                    return (
                        <div key={key} className="much much-less-height">
                            {value.map((v, i)=>{
                                const dataObj = {[i]:v};
                                return <ObjectGrid key={key + "_" + i} dataObj={dataObj} depth={depth +1}/>
                            })}
                        </div>
                    )
                }
                else
                {
                    return null;
                
                }
            })}
        </div>
    );
}
