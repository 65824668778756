import '../actions/globalActions';
import { k_setLoggedInUser, k_setIsShowingUserNav, k_setIsShowingDetailedMaster } from '../actions/globalActions';

const initialState = {
    userClaims: null,
    showingUserNav: false,
    showingDetailedMaster: false,
}

export default (state = initialState, { type, payload }) => {
    //console.log(`Reducing ${type}: ${payload}`)
    switch (type) {

    case k_setLoggedInUser:
        return { ...state, userClaims:payload }

    case k_setIsShowingUserNav:
        return {...state, showingUserNav: payload}
    
    case k_setIsShowingDetailedMaster:
        return {...state, showingDetailedMaster: payload}
    default:
        return state
    }
}
