export const vars = {
    DB: {
        TABLES: {
            TENANT: "tenants",
            USERS: "users",
            //ROLES:"Roles",
            CAMERAS: "cameras",
            REVISION: "global/revisions",
            FARMS: "farms",
            SESSIONS: "sessions",
            SESSION_METADATA0: "meta/0",
            SESSION_METADATA: "meta",
            EVENTS: "events",
            EVENTS_OWNED: "events_owned",
            EVENTS_CAMERA: "events_camera",
            ORPHAN_EVENTS: "orphanEvents"
        }
    },
    FAULT_TYPES: [
        "None",
        "Low Battery Shutdown",
        "Low Diskspace",
        "Queue Large",
        "Queue Full",
        "Low Battery",
        "Docker Connection"
    ],
    sessionCategories: {
        COMPLETE: "complete",
        ACTIVE: "active",
        INCOMPLETE_START_MISSING: "incomplete_startMissing",
        INCOMPLETE_NO_END_NEWER_SESSION: "incomplete_noEndNewerSession",
        INCOMPLETE_NO_END_NEWER_CAM_EVENTS: "incomplete_noEndNewerCamEvents",
        ABORTED: "aborted",
        OVERFLOWN: "overflown",
        ACTIVE_NO_CONNECTION: "acitve_noConnection",
        ALARMED: "alarmed",
        UNKNOWN: "unknown"
    },
    eventCommandTypes: {
        //Events not originating from the camera
        SESSION_END_INTERPRETED_NEW_SESSION_STARTED: { command: "S1", commandString: "SESSION_END_INTERPRETED_NEW_SESSION_STARTED" },
        SESSION_END_MANUAL: { command: "S2", commandString: "SESSION_END_MANUAL" },
        SESSION_START_MANUAL: { command: "S3", commandString: "SESSION_START_MANUAL" },
    },
    faultCodes: {
        NONE: 0,
        LOW_BATTERY: 1,
        LOW_DISK_SPACE: 2,
        QUEUE_LARGE: 3,
        QUEUE_FULL: 4,
    }
}

export const camStates = {
    off: "OFF",
    standby: "STANDBY",
    charging: "CHARGING",
    running: "RUNNING",
    runningPaused: "RUNNING_PAUSED",
    runningAborted: "RUNNING_ABORTED",
    assisting: "ASSISTING",
    fault: "FAULT",
    pigletCount: "PIGLET_COUNT",
    runningEnded: "RUNNING_ENDED",
    alarmed: "ALARMED",
    sleep: "SLEEP",
    unknown: "UNKNOWN",
    poweredDown: "POWERED_DOWN",
    faultShutdown: "FAULT_SHUTDOWN",
    serviceRequired: "SERVICE_REQUIRED",
}
