import {vars} from "./../vars"

export const getManualEndEvent = ({cameraId, sessionId, pigletsBornDead, pigletsBornAlive}) => {
    
    return {
        cameraId,
        sessionId,
        pigletsBornDead,
        pigletsBornAlive,
        ...vars.eventCommandTypes.SESSION_END_MANUAL,
        timestamp: Date.now(),

    }
}