import React, { useState } from 'react'
import "../../modules/firebase/auth";
import { useFirebase } from 'react-redux-firebase';
import { useHistory, useLocation } from 'react-router-dom';
import M from "materialize-css";
import { Modal, Button, TextInput, Icon, Preloader, Select, Col, Row, Divider} from 'react-materialize';

import {  ConfirmModal } from './../Util/ModalForms';
/*
const signInForm = document.querySelector("#signin-form");

console.log("Loaded auth file")
signInForm.addEventListener("submit", (e)=>{
    e.preventDefault();

    const email = signInForm['signin-email'];
    const password = signInForm['signin-password'];

    console.log("Email: ", email);

});

export defaul*/


const SignIn = (props) =>  {

    const firebase = useFirebase();
    const history = useHistory();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showingResetModal, setShowingResetModal] = useState(false);
    const [signinFailed, setSigninFailed] = useState(false);
    const handleEmailChange= (e) => {
        setEmail(e.target.value);
    }
    
    const handlePasswordChange= (e) => {
        setPassword(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        
        firebase.auth().signInWithEmailAndPassword(email, password)
        .then((userCred)=> {
            M.toast({html:"Log in success"})
            //console.log("Got user credentials: ",userCred);
            history.push("/");
        })
        .catch(e=>{
            setSigninFailed(true);
            M.toast({html:`GOt sign in error: ${e}`})
        })

    }
    

    return (
        <div className="container centre">
            <ConfirmModal onConfirm={()=>firebase.auth().sendPasswordResetEmail(email, {url:window.location.href})} onCancel={()=>setShowingResetModal(false)} open={showingResetModal} title="Reset Password" content={`Send email to ${email} with password reset link?`}>
                <div> <div className="center-align"><h5>Reset Password</h5></div><p>Send email to {email} with password reset link?</p></div>
            </ConfirmModal>
            <h4>Welcome to Farrow App</h4>
            <div style={{height:"5em"}}></div>
            <form onSubmit={handleSubmit}  className="white" id="signin-form">
            <div className="input-field">
                <label htmlFor="email" >Email</label>
                <input type="email" id="signin-email" onChange={handleEmailChange}/> 
            </div>
            <div className="input-field">
                <label htmlFor="password" >Password</label>
                <input type="password" id="signin-password" onChange={handlePasswordChange}/>
            </div>
            <Button className="left">
                Log in
            </Button>
            {signinFailed && 
            <Button onClick={()=>setShowingResetModal(true)} className=" right amber">Reset Password</Button>}

            </form>
        </div>
    );
}

export default SignIn
