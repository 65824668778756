import React from 'react'
import {KeyValGrid} from './KeyValGrid';
import {Row, Col} from 'react-materialize';
import { vars } from '../../vars';

export const EventHeader = (props) => {
    
    const timingIssue = Math.abs(props.timestamp - props.timestamp_server) > 60 * 1000;
    
    return ( props.timestamp == 0 ? 
        <Row style={{width:"100%"}}className="center-align">
            <Col m={2}>{props.name}</Col>
            <Col m={10}>None received</Col>
        </Row> : 
            <Row style={{width:"100%", height:"1em"}} className="centser-align">
            <Col m={3}>{props.name}</Col>
            <Col m={2}>{"~" + (Math.floor((props.timeNow -props.timestamp) / 60_000) + " mins ago"|| "?")}</Col>
            <Col m={2}>Battery: {props.battery || "?"}%</Col>
            <Col m={3}>Type: {props?.commandString || "?"}</Col>
            <Col m={timingIssue ? 1 : 2}>Sig: {props.signalStrength || "?"} db</Col>
            { timingIssue && <Col style={{backgroundColor:"pink"}} m={1}>! Timing !</Col>}
        </Row>
        
    )
}

export const FarmEventHeader = ({timestamp, timestamp_server, commandString, type, faultCode, cameraId}) => {
    const faultName = faultCode && vars.FAULT_TYPES[faultCode];
    return ( timestamp == 0 ? 
        <Row style={{width:"100%"}}className="center-align">
            
            <Col m={12}>None received</Col>
        </Row> : 
            <Row style={{width:"100%", height:"1em"}} className="centser-align">
            <Col m={4}>{new Date(timestamp).toLocaleString("en-GB")}</Col>
            <Col m={4}>{faultName ||commandString || "?"}</Col>
            
            <Col m={3}>{cameraId}</Col>
        </Row>     
    )
}

export const EventDetail = (props) => {

    const valsWithTimestrings = props.keyValTupleArray.sort();

    for(var i = 0; i < valsWithTimestrings.length; i++)
    {
        const keyAndVal = valsWithTimestrings[i];
        //console.log("Key: ", key);
        //console.log("Val: ", val);
        if(keyAndVal[0].includes("timestamp"))
        {
            if(keyAndVal[0].includes("_gaps"))
            {
                const gapArr = keyAndVal[1];
                keyAndVal[1] =`${gapArr[0]} : ${gapArr[1]} : ${gapArr[2]}`
            }
            else{
                const date = new Date(keyAndVal[1])
                keyAndVal[1] = date.toLocaleString("da-DK") + ":" + date.getMilliseconds();
            }
        }
    };

    const arrLength = valsWithTimestrings.length;
    const firstLength = Math.ceil(arrLength / 2); 
    const first = valsWithTimestrings.slice(0, firstLength);
    const last = valsWithTimestrings.slice(firstLength, arrLength);

    
    return (
        
        <Row className="centser-align">
            <Col m={6}>
                <KeyValGrid propAndValuePairArray={first}/>
            </Col>
            <Col m={6}>
                <KeyValGrid propAndValuePairArray={last}/>
            </Col>
        </Row>
    )
}
