import globalReducer from "./globalReducer";
import farmsReducer from "./farmsReducer";
import userReducer from "./userReducer";
import {combineReducers} from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from 'redux-firestore' // <- needed if using firestore



const rootReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    global: globalReducer,
    user: userReducer,
    farms: farmsReducer
})

export default rootReducer;