import {camStates} from "./../../vars";

export const colorsForCamStates =
{
    [camStates.assisting]: "blue",
    [camStates.standby]: "green",
    [camStates.charging]: "green",
    [camStates.running]: "green",
    [camStates.runningAborted]: "grey",
    [camStates.runningPaused]: "blue",
    [camStates.runningEnded]: "green",
    [camStates.pigletCount]: "green",
    [camStates.fault]: "red darken-1",
    [camStates.alarmed]: "red darken-1",
    [camStates.sleep]: "green",
    [camStates.off]: "grey",
    [camStates.unknown]: "grey",
    [camStates.poweredDown]: "grey",
    [camStates.faultShutdown]: "red darken-1",
    [camStates.serviceRequired]: "red darken-1",
}

export const getCamStateColor = ({camState, connection}) => {
    //console.log("Cam State: ", camState);
    return !connection ?
    (camState == camStates.off || camState == camStates.unknown ? "grey" : "red darken-2") :
    colorsForCamStates[camState];
}

export const getColorForReactionTime = (timeSecs) => {
    //timeSecs *= 60;

    return timeSecs >= 4 * 60 ? "red" : timeSecs > 2 * 60 ? "yellow" : "green";
}