import React, {useEffect} from 'react';
import {BrowserRouter, Switch, Route, useLocation, useHistory} from 'react-router-dom'
import {TenantMasterNavbar} from './components/navvbar/navbar'
import SignIn from "./components/auth/SignIn"
import TestContent from "./components/testContent"
import Welcome from './components/welcome'
import Users from './components/users';

import Tenants from './components/tenants'
import ApiKeys from './components/ApiKeys';
import { useFirebase, useFirebaseConnect, useFirestoreConnect } from 'react-redux-firebase';
import { connect, useSelector } from 'react-redux'
import {TenantMasterDetail} from './components/TenantDetail';
import {Dashboard} from './components/Dashboard';
import FarmDetail from './components/FarmDetail';
import {CameraDetailView} from "./components/CameraDetail";
import {CameraEventsConnected, CameraEventsFromLocationConnected} from "./components/CameraEvents";
import {SessionEventsConnected} from "./components/SessionEvents";
import {Cameras} from "./components/Cameras";
import {SessionsViewWithDataSubscription} from "./components/Sessions";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

import {setupMessaging} from './modules/firebase/cloudMessaging';
import { CameraSessionsWithFirestore } from './components/CameraSessions';

var gitVersion = "xx";
try{
  var version = require("./version.js");
  gitVersion = version.gitCommitId.substr(0,4);
}
catch(e){
  gitVersion = "Not Loaded";
}
export const InnerApp = () => {

  const userClaims = useSelector(state => state.global?.userClaims);
  const showingUserNav = useSelector(state => state.global.showingUserNav);
  const firebase = useFirebase();
  const history = useHistory();

  const pushHistory = (destination)=>{history.push(destination)}

  useEffect(() => {
    //console.log("Claims changed");
    
    setupMessaging(pushHistory);
  }, [userClaims])
  //setPushHistory(history.push);
  return (
    
      <div className="App">
        {!showingUserNav && userClaims && userClaims?.role !== "user" && <TenantMasterNavbar/>}
        {!userClaims ?
        <Switch>
          <Route path="/" component={SignIn}/>
          
        </Switch>
        :
          <Switch>
            <Route exact path="/testContent" component={TestContent}/>
            <Route exact path="/" component={Dashboard}/>
            <Route exact path="/cameras" component={Cameras}/>
            
            <Route exact path="/cameras/:cameraId" component={CameraDetailView}/>
            <Route exact path="/cameras/:cameraId/sessions" component={CameraSessionsWithFirestore}/>
            <Route exact path="/cameras/:cameraId/events" component={CameraEventsFromLocationConnected}/>
            <Route exact path="/tenants" component={Tenants}/>
            <Route exact path="/tenants/:tenantId" component={TenantMasterDetail}/>
            <Route exact path="/tenants/:tenantId/users/:userId" component={Users}/>
            <Route exact path="/tenants/:tenantId/farms/:farmId" component={userClaims?.role === "user" ? SessionsViewWithDataSubscription : FarmDetail}/>
            <Route exact path="/tenants/:tenantId/farms/:farmId/sessions" component={SessionsViewWithDataSubscription}/>
            <Route exact path="/tenants/:tenantId/farms/:farmId/cameras/:cameraId/sessions/:sessionId/events" component={SessionEventsConnected}/>
            <Route exact path="/tenants/:tenantId/farms/:farmId/cameras/:cameraId/events" component={CameraEventsConnected}/>
            <Route exact path="/tenants/:tenantId/cameras/:cameraId" component={CameraDetailView}/>
            
            <Route exact path="/tenants/:tenantId/farms/:farmId/cameras/:cameraId" component={CameraDetailView}/>
            <Route exact path="/tenants/:tenantId/farms/:farmId/cameras/:cameraId/sessions" component={CameraSessionsWithFirestore}/>
            
            <Route exact path="/tenants/:tenantId/cameras/:cameraId/events" component={CameraEventsConnected}/>
            
            <Route exact path="/apiKeys" component={ApiKeys}/>
            
          </Switch>
        }
        
       
        <ToastContainer 
        autoClose={10000}
          /*position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover*//>
        <div style={{position: "fixed", bottom:0, right:"1em", fontSize:"0.7em"}}>Ver. {gitVersion}</div>
      </div>
  );
}

export const App = () => {
  return (
    <BrowserRouter>
      <InnerApp>
      </InnerApp>
    </BrowserRouter>
    )
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(App)

