export const k_setLoggedInUser = "SET_LOGGED_IN_USER";
export const k_setIsShowingUserNav = "SET_SHOWING_USER_NAV";
export const k_setIsShowingDetailedMaster = "SET_SHOWING_DETAILED_MASTER";

export const setLoggedInUserClaims = (claimsObj) => {
    return {
        type: k_setLoggedInUser,
        payload: claimsObj,
    }
} 

export const setShowingUserNav = (isShowing) => {
   
    return {
        type: k_setIsShowingUserNav,
        payload: isShowing,
    }
} 

export const setShowingDetailedMaster = (isShowing) => {
   
    return {
        type: k_setIsShowingDetailedMaster,
        payload: isShowing,
    }
} 