import React from 'react'
import {Collection, CollectionItem} from 'react-materialize';
import { Link } from 'react-router-dom';

export default ({nameAndLinkPairArray}) => {
    return(
        nameAndLinkPairArray === null ? "...loading...." :
            nameAndLinkPairArray.length === 0 ? "empty" :
            <Collection>
            
            {nameAndLinkPairArray.map((nameAndLinkPair, i)=>{
                return (
                    <CollectionItem key={nameAndLinkPair[1]}>
                        <Link to={nameAndLinkPair[1]}>
                            {nameAndLinkPair[0]}
                        </Link>
                    </CollectionItem>
                    )
                })}
            </Collection>
        
    );
}
